import ilvoComponent from './ilvoComponent';
import domManager from '../utils/domManager';


export default class registerIlvo1 extends ilvoComponent {
    constructor(parentContainer, ownContainer) {
        super(parentContainer, ownContainer);

        var reCaptchaSiteKey = window.appConfig.reCaptchav3SiteKey;

        this.htmlString = `
        <div class="loginIlvo1" id="${this.ownContainer}">
    <div class="fw-header">
        <div class="fw-width">
            <img src="/project/img/${sharedFunctions.getEnvironmentLogo()}" />
            <div class="user-header-info"></div>
        </div>
    </div>
            <section class="ftco-section">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-6">
                                <img id="login-project-logo" />

                            <div class="wrap">
                                <div class="login-wrap p-4 p-md-5">
                                    <div id="d1-auth-success" style="display:none">
<span id="d1-success-icon" class="material-icons">check_circle</span> ${sharedFunctions.getWebAppTranslation("fe34")}
                                    </div>
                                    <div id="d1-auth-user-input">
                                        <div class="d-flex d1-auth-title">
                                            <div class="w-100">
                                                <h3 class="mb-4">${sharedFunctions.getWebAppTranslation("fe35")}</h3>
                                            </div>
                                        </div>
                                        <div id="d1-register-validation"></div>
                                        <form id="d1-register-form" action="#" class="signin-form">
                                            <input type="hidden" id="d1-register-captcha" name="captcha" />
                                            <div class="form-group mt-3">
                                                <div class="dx-field">
                                                    <div id="d1-register-email">
                                                    </div>
                                                </div>
                                                <div class="dx-field" id="d1-register-user-container">
                                                    <div id="d1-register-user">
                                                    </div>
                                                </div>
                                                <div class="dx-field">
                                                    <div id="d1-register-pw">
                                                    </div>
                                                </div>
                                                <div class="dx-field">
                                                    <div id="d1-register-pwconfirm">
                                                    </div>
                                                </div>
                                                <div id="d1-register-custom-container" class="dx-field">
                                
                                                </div>
                                                <div class="dx-field">
                                                        <div id="d1-register-privacy">
                                                        </div><div style="display: inline-block;vertical-align: middle;">&nbsp;<a href="#/privacy">${sharedFunctions.getWebAppTranslation("fe36")}</a></div>
                                                    </div>
                                                <div class="dx-field">
                                                    <div id="d1-register-btn">
                                                    </div>
                                                </div>
                                                
                                        </form>
                                        <p style="margin-top:10px" class="text-center">Already have an account? <a href="#/login">${sharedFunctions.getWebAppTranslation("fe37")}</a></p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
<script src="https://www.google.com/recaptcha/api.js?render=${reCaptchaSiteKey}"></script>
        `;

    }

    render(currUrlObj, lastUrlObj) {
        this.currUrlObj = currUrlObj;
        this.lastUrlObj = lastUrlObj;

        this.clearRenderedData();

        this.renderCoreContent();

        //loadAndRender is not split up yet
        this.loadAndRenderData();

        this.addEventHandlers();
    }

    clearRenderedData() {
        if (domManager.existsId(this.ownContainer)) {
            domManager.removeId(this.ownContainer);
        }
    }

    loadAndRenderData() {
        if (window.appConfig.user && window.appConfig.user.username) {
            window.location.hash = window.appConfig.defaultUrl;
        }

        window.currentPage = 'register';

         sharedFunctions.addUserInHeader("#" + this.ownContainer + " .user-header-info");

        $('#d1-register-validation').dxValidationSummary({});

        $("#d1-register-email").dxTextBox({
            placeholder: sharedFunctions.getWebAppTranslation("fe46"),
            height: 48,
            name: "email",
            mode: 'email'
        }).dxValidator({
            validationRules: [{
                type: 'required',
                message: sharedFunctions.getWebAppTranslation("fe47"),
            }, {
                type: 'email',
                message: sharedFunctions.getWebAppTranslation("fe48")
            }],
        });

        if (window.appConfig.requireUsernameForRegister) {
            $("#d1-register-user").dxTextBox({
                placeholder: sharedFunctions.getWebAppTranslation("fe49"),
                name: "username",
                height: 48
            }).dxValidator({
                validationRules: [
                    {
                        type: 'required',
                        message: sharedFunctions.getWebAppTranslation("fe50"),
                    }
                ],
            });
        } else {
            $("#d1-register-user-container").hide();
        }

        $("#d1-register-pw").dxTextBox({
            placeholder: sharedFunctions.getWebAppTranslation("fe40"),
            name: "password",
            height: 48,
            mode: "password",
            inputAttr: { autocomplete: "new-password" }
        }).dxValidator({
            validationRules: [{
                type: 'required',
                message: sharedFunctions.getWebAppTranslation("fe41"),
            },
            {
                type: 'stringLength',
                min: 8,
                //message: 'Name must have at least 2 symbols',
            }],
        });
        $("#d1-register-pwconfirm").dxTextBox({
            placeholder: sharedFunctions.getWebAppTranslation("fe42"),
            name: "confirmpassword",
            height: 48,
            mode: "password",
            inputAttr: { autocomplete: "new-password" }
        }).dxValidator({
            validationRules: [{
                type: 'required',
                message: sharedFunctions.getWebAppTranslation("fe43"),
            },
            {
                type: 'stringLength',
                min: 8,
                //message: 'Name must have at least 2 symbols',
            }],
        });
        $("#d1-register-privacy").dxCheckBox({
            text: sharedFunctions.getWebAppTranslation("fe51"),
            name: "privacyconsent"
        });
        $("#d1-register-btn").dxButton({
            stylingMode: "contained",
            text: sharedFunctions.getWebAppTranslation("fe35"),
            type: "default",
            width: "100%",
            height: 48,
            useSubmitBehavior: true,

        });

        $('#d1-register-form').on('submit', (e) => {
            grecaptcha.ready(function () {
                grecaptcha.execute(window.appConfig.reCaptchav3SiteKey, { action: 'register' }).then(function (token) {
                    $('#d1-register-captcha').val(token);
                    var formData = $('#d1-register-form').serializeArray();
                    formData.push({ name: "language", value: sharedFunctions.getLanguage() });
                    sharedFunctions.showLoadPanel();
                    $.ajax({
                        method: "POST",
                        data: formData,
                        url: window.fwApi + "/register"
                    })
                        .done(function (result) {
                            if (result.Error) {
                                sharedFunctions.showMessage(result.Error, sharedFunctions.getWebAppTranslation("fe13"));
                            } else {
                                $("#d1-auth-user-input").hide();
                                $("#d1-auth-success").show();
                            }

                        })
                        .fail(function (result) {
                            //$("#d1-register-pw").dxTextBox("option", "value", "");
                            //$("#d1-register-pwconfirm").dxTextBox("option", "value", "");

                            var errorResult = JSON.parse(result.responseText);
                            var errorHtml = "";
                            if (errorResult.Error) {
                                errorHtml += "<p>" + errorResult.Error + "</p>";
                            }
                            else if (errorResult.Errors) {
                                for (var i = 0; i < errorResult.Errors.length; i++) {
                                    errorHtml += "<p>" + errorResult.Errors[i] + "</p>";
                                }
                            }

                            $("#d1-register-validation").html(errorHtml);
                        })
                        .always(function (result) {
                            sharedFunctions.hideLoadPanel();
                        });
                });
            });
            e.preventDefault();
        });

        if (window.addCustomRegisterFields) {
            $("#d1-register-custom-container").show();
            window.addCustomRegisterFields();
        }
    }

    addEventHandlers() {
        var classRef = this;
        $('#' + classRef.ownContainer + ' .d1-lang-item').off('click');
        $('#' + classRef.ownContainer + ' .d1-lang-item').on('click', function () { sharedFunctions.changeLanguage($(this).attr('lang')) });
    }
}


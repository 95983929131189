export default class sharedFunctions {
    constructor() {
    }

    getSelectedKeys() {
        var selection = this.getSelectedData();
        if (selection.length === 0) return [];
        return selection.map(x => x.ID);
    }

    getSelectedData() {
        var grid = $("#datagridDx1Container").dxDataGrid("instance");
        if (grid === undefined) return [];
        //grid.selectRows(grid.getSelectedRowKeys(), false);
        var selected = grid.getSelectedRowsData();
        for (let i = 0; i < selected.length; i++) {
            if (grid.getRowIndexByKey(selected[i].ID) < 0) {
                grid.deselectRows([selected[i].ID]);
            }
        }

        return selected;
    }

    getGrid() {
        var grid = $("#datagridDx1Container").dxDataGrid("instance");
        if (grid === undefined) return null;
        return grid;
    }

    getColumnsOld(columnsResult) {
        var columns = {};
        if (columnsResult) {
            columns = columnsResult[0];

            for (var i = 0; i < columns.length; i++) {
                if (columns[i].lookup) {
                    //var temp = columns[i].lookup.dataSource;
                    columns[i].lookup.dataSource = {
                        paginate: true,
                        pageSize: 20,
                        store: this.createStore(window.fwApi + '/' + columns[i].lookup.dataSource, currentModule)
                    }
                }
            }
        }
        else {
            columns = window.columnData.find(x => x.name === currentModule).columns;
        }


        if (columns.length > 0) {
            var columnConfig = window.columnData.find(x => x.name === currentModule);
            if (columnConfig) {
                columnConfig.columns = columns;
            }
            else {
                window.columnData.push({ name: currentModule, columns: columns });
                columnConfig = { name: currentModule, columns: columns };
            }
        }

        return columnConfig.columns;
    }

    createStore(url, module, dataField) {

        var classRef = this;
        return new DevExpress.data.CustomStore({
            cacheRawData: false,
            loadMode: "processed",
            load: function (loadOptions) {
                var d = $.Deferred();
                var params = {};

                [
                    "filter",
                    "group",
                    "groupSummary",
                    "parentIds",
                    "requireGroupCount",
                    "requireTotalCount",
                    "searchExpr",
                    "searchOperation",
                    "searchValue",
                    "select",
                    "sort",
                    "skip",
                    "take",
                    "totalSummary",
                    "userData"
                ].forEach(function (i) {
                    if (i in loadOptions && classRef.isNotEmpty(loadOptions[i])) {
                        params["options:" + i] = JSON.stringify(loadOptions[i]);
                    }
                });

                var lookupElem =
                    window.lookupQsDict.find(x => x.module === window.currentModule && x.lookup === dataField);
                var parentQs = "";
                var customQs = "";
                if (lookupElem) {
                    if (!lookupElem.parentQs.endsWith("=null")) {
                        parentQs = lookupElem.parentQs;
                    }
                    customQs = lookupElem.customQs;
                }

                var hash = window.location.hash.substr(1);

                hash.split('&').reduce(function (res, item) {
                    var parts = item.split('=');
                    if (parts[0].startsWith('bc:') || parts[0] === "ids") {
                        params[parts[0]] = parts[1];
                    }
                });
                if (window.hasCustomStore && window.hasCustomStore(url, module)) {
                    return window.customStore(url, module, parentQs, customQs, params);
                }
                return $.getJSON(url + "&listModule=" + module + parentQs + customQs, params);
            },
            byKey: function (key) {
                return $.get(url.replace('lookup', 'lookup/item') + "&id=" + key.toString());
            }
        });
    }

    isNotEmpty(value) {
        return value !== undefined && value !== null && value !== "";
    }

    queryStringToJsonObj(qs) {
        qs = qs || location.search.slice(1);

        var pairs = qs.split('&');
        var result = {};
        pairs.forEach(function (p) {
            var pair = p.split('=');
            var key = pair[0];
            var value = decodeURIComponent(pair[1] || '');

            if (result[key]) {
                if (Object.prototype.toString.call(result[key]) === '[object Array]') {
                    result[key].push(value);
                } else {
                    result[key] = [result[key], value];
                }
            } else {
                result[key] = value;
            }
        });

        return JSON.parse(JSON.stringify(result));
    }

    getCurrentUrlObj() {
        var n = window.location.hash.lastIndexOf('?');
        var urlObj = {};
        if (n !== -1) {
            urlObj.url = window.location.hash.substr(0, n);
            var locqs = window.location.hash.substr(n + 1);
            urlObj.qsObj = this.queryStringToJsonObj(locqs);
        } else {
            urlObj.url = window.location.hash;
            urlObj.qsObj = {}
        }
        return urlObj;
    }

    updateListDetailsConfigDict(listDetailsConfigResult) {
        if (listDetailsConfigResult) {

            var ldConfigLoc = listDetailsConfigResult;
            var columns = {};
            if (ldConfigLoc.columns) {
                columns = ldConfigLoc.columns;

                for (var i = 0; i < columns.length; i++) {
                    if (columns[i].lookup) {
                        columns[i].lookup.dataSource = {
                            paginate: true,
                            pageSize: 20,
                            store: this.createStore(window.fwApi + '/' + columns[i].lookup.dataSource, currentModule, columns[i].dataField)
                        }
                    }
                    if (columns[i].columnType === "Color") {
                        
                        columns[i].cellTemplate = function (container, options) {
                            //console.log(options);
                            var color = options.data.ColorCode;
                            if (options.column.colorColumn) {
                                var colorColumn = options.column.colorColumn;
                                color = options.data[colorColumn];
                            }
                            $("<div class='fw-color-cell' style='background-color:" + color + "'></div><span> " + options.text + "</span>").appendTo(container);
                        }
                    }
                    if (columns[i].columnType === "ShortLat") {
                        columns[i].cellTemplate = function (container, options) {
                            if (options.text !== "") {
                                var isNegative = options.text < 0;

                                var degreesAbs = Math.abs(options.text);
                                var degrees = Math.floor(degreesAbs);
                                var minutesNotTruncated = (degreesAbs - degrees) * 60;
                                var minutes = Math.floor(minutesNotTruncated);
                                var secondsNotTruncated = Math.round((minutesNotTruncated - minutes) * 1000);
                                if (secondsNotTruncated > 999) {
                                    minutes += 1;
                                    secondsNotTruncated = 0;
                                }
                                secondsNotTruncated = secondsNotTruncated.toString().replace("0.", "").replace("0,", "").substr(0, 3);

                                if (degrees.length === 1) {
                                    degrees = "0" + degrees;
                                }
                                minutes = minutes.toString();
                                if (minutes.length === 1) {
                                    minutes = "0" + minutes;
                                }
                                if (secondsNotTruncated.length === 1) {
                                    secondsNotTruncated = "0" + secondsNotTruncated;
                                }
                                if (secondsNotTruncated.length === 2) {
                                    secondsNotTruncated = "0" + secondsNotTruncated;
                                }

                                var result = degrees.toString().padStart(2, '0') + "\xB0" + minutes + "," + secondsNotTruncated + "'";
                                if (isNegative) {
                                    result = "-" + result;
                                } else {
                                    result = "+" + result;
                                }
                                $("<div>" + result + "</div>").appendTo(container);
                            }
                        }
                    }

                    if (columns[i].columnType === "ShortLon") {
                        columns[i].cellTemplate = function (container, options) {
                            if (options.text !== "") {
                                var isNegative = options.text < 0;

                                var degreesAbs = Math.abs(options.text);
                                var degrees = Math.floor(degreesAbs);
                                var minutesNotTruncated = (degreesAbs - degrees) * 60;
                                var minutes = Math.floor(minutesNotTruncated);
                                var secondsNotTruncated = Math.round((minutesNotTruncated - minutes) * 1000);
                                if (secondsNotTruncated > 999) {
                                    minutes += 1;
                                    secondsNotTruncated = 0;
                                }
                                secondsNotTruncated = secondsNotTruncated.toString().replace("0.", "").replace("0,", "").substr(0, 3);

                                if (degrees.length === 1) {
                                    degrees = "0" + degrees;
                                }
                                if (degrees.length === 2) {
                                    degrees = "0" + degrees;
                                }
                                minutes = minutes.toString();
                                if (minutes.length === 1) {
                                    minutes = "0" + minutes;
                                }
                                if (secondsNotTruncated.length === 1) {
                                    secondsNotTruncated = "0" + secondsNotTruncated;
                                }
                                if (secondsNotTruncated.length === 2) {
                                    secondsNotTruncated = "0" + secondsNotTruncated;
                                }

                                var result = degrees.toString().padStart(3, '0') + "\xB0" + minutes + "," + secondsNotTruncated + "'";
                                if (isNegative) {
                                    result = "-" + result;
                                } else {
                                    result = "+" + result;
                                }
                                $("<div>" + result + "</div>").appendTo(container);
                            }

                        }
                    }


                    //https://supportcenter.devexpress.com/ticket/details/t554390/dxdatagrid-cell-customization-with-dynamic-progress-bar/
                }
            }
            ldConfigLoc.columns = columns;

            //var ldConfigFromDict = window.listDetailsConfigDict.find(x => x.name === currentTable);
            //if (ldConfigFromDict) {
            //    ldConfigFromDict.listdetailsconfig = ldConfigLoc;
            //    }
            //else {
            window.listDetailsConfigDict.push({ name: currentModule, listdetailsconfig: ldConfigLoc });

            // }
        }
    }

    updateLookupQsDict(listDetailsConfigResult) {
        if (listDetailsConfigResult) {
            var ldConfigLoc = listDetailsConfigResult;
            var columns = {};
            if (ldConfigLoc.columns) {
                columns = ldConfigLoc.columns;
                for (var i = 0; i < columns.length; i++) {
                    if (columns[i].parentLookupKey && columns[i].parentLookupKey != "") {
                        window.lookupQsDict.push({
                            module: window.currentModule, lookup: columns[i].dataField, parentLookup: columns[i].parentLookupKey, parentQs: "&" + columns[i].parentLookupKey + "=null", customQs: ""
                        });
                    }
                }
            }
        }
    }

    getColumns() {
        return window.listDetailsConfigDict.find(x => x.name === currentModule).listdetailsconfig.columns;
    }

    showToast(message, type, duration) {
        var maxWidth = 150;
        if (message.length > 25) {
            maxWidth = 300;
        }
        if (message > 100) {
            maxWidth = 600;
        }
        DevExpress.ui.notify({ message: message, position: "top center", maxWidth: maxWidth }, type, duration);
    }

    showMessage(message, title) {
        DevExpress.ui.dialog.alert(message, title);
        window.loadPanel.hide();
    }

    hasPermission(module, action) {
        if (!window.permissions) return false;
        var exists = window.permissions.find(x => x.Key === module && x.Value === action);
        if (exists) return true;
        return false;

    }

    showLoadPanel() {
        window.loadPanel.show();
    }

    hideLoadPanel() {
        window.loadPanel.hide();
    }

    navigateToUrl(url) {
        if (url.startsWith('http')) {
            window.location.href = url;
            window.location.reload();
        } else {
            window.location.hash = url;
        }
    }

    getWebAppTranslation(key, placeholder) {
        if (!placeholder) {
            placeholder = "!" + key;
        }
        if (!window.appConfig) {
            return placeholder;
        }
        var label = window.appConfig.translations.find(x => x.id === key) ? window.appConfig.translations.find(x => x.id === key).label : placeholder;

        return label;
    }

    addUserInHeader(container) {
        var classRef = this;
        if (appConfig.user.userID != null) {

            if (appConfig.authenticationMode !== "Credentials" && appConfig.authenticationMode !== "Azure" && !appConfig.isImpersonated) {
                var customUrlUser = '#/details' + '?module=User&mode=navToPrev&ids=' + appConfig.user.userID;
                $(container).html("<div class='user-info' webModule='" +
                    "" +
                    "' customUrl='" +
                    customUrlUser +
                    "'><span class='ilvo-large' id='user-name-full'>" +
                    appConfig.user.username +
                    "</span><span class='ilvo-small' id='user-name-short'><span class='material-icons'>person</span></span></div>");

                $(container + ' .user-info').click(function () {
                    var customUrl = $($(this)[0]).attr('customUrl');
                    if (customUrl.length > 0) {
                        window.location.hash = customUrl;
                    } else {
                        var module = $($(this)[0]).attr('webModule');
                        window.location.hash = '#/list' + '?module=' + module;
                    }
                });
            } else {
                var items = [
                    { name: "Edit", label: classRef.getWebAppTranslation("edit") },
                    
                ];

                if (appConfig.authenticationMode === "Credentials" || appConfig.authenticationMode === "Azure") {
                    items.push({ name: "Logout", label: classRef.getWebAppTranslation("fe52") });
                }

                if (appConfig.isImpersonated) {
                    items.push({ name: "LogoutAs", label: "Logout as " + appConfig.user.username });
                }

                $(container).dxDropDownButton({
                    text: '',
                    icon: 'user',
                    dropDownOptions: {
                        width: 200,
                    },
                    onItemClick(e) {
                        if (e.itemData.name === 'Edit') {
                            var customUrlUser =
                                '#/details' + '?module=User&mode=navToPrev&ids=' + appConfig.user.userID;
                            window.location.hash = customUrlUser;
                        } else if (e.itemData.name === 'Logout') {
                            classRef.logout();
                        }
                        else if (e.itemData.name === 'LogoutAs') {
                            window.personate();
                        }
                    },
                    items: items,
                    displayExpr: "label"
                });

                //$('.user-header-info').dxDropDownButton({
                //    text: '',
                //    icon: 'user',
                //    dropDownOptions: {
                //        width: 200,
                //    },
                //    onItemClick(e) {
                //        if (e.itemData.name === 'Edit') {
                //            var customUrlUser =
                //                '#/details' + '?module=User&mode=navToPrev&ids=' + appConfig.user.userID;
                //            window.location.hash = customUrlUser;
                //        } else if (e.itemData.name === 'Logout') {
                //            classRef.logout();
                //        }
                //    },
                //    items: items,
                //    displayExpr: "label"
                //});

            }
        } else {
            var langHtml = "";
            if (window.appConfig.languages.length > 1) {
                for (var i = 0; i < window.appConfig.languages.length; i++) {
                    langHtml += "<div class='d1-lang-item' lang='" + window.appConfig.languages[i].Code + "'>" + window.appConfig.languages[i].Code.toUpperCase() + "</div>";
                }
            }
            $(container).html(langHtml);
        }
    }

    logout() {
        var classRef = this;

        if (window.appConfig.authenticationMode !== "Credentials" && window.appConfig.authenticationMode !== "Azure") {
            return;
        }

        window.appConfig.user = null;

        if (window.appConfig.authenticationMode === "Azure") {
            window.location.href = "../home/logout";
        }


        classRef.showLoadPanel();
        $.ajax({
            method: "GET",
            url: window.fwApi + "/logout"
        })
            .always(function (result) {
                var storageLanguage = localStorage.getItem('language');
                //load appConfig.json
                $.ajax({
                    method: "GET",
                    url: window.fwApi + "/appconfig?language=" + storageLanguage
                })
                    .done(function (result) {
                        if (result.Error) {
                            classRef.showMessage(result.Error, classRef.getWebAppTranslation("fe13"));
                        } else {
                            window.appConfig = result.Result;
                            window.location.hash = "#/login";
                            window.navMenu.refresh();
                        }
                    }).always(function (result) {
                        classRef.hideLoadPanel();

                    });
            })
            .fail(function (result) {

                // handle request failures
            });

    }

    updateOneToOneRelAppBar(detailsUrl, classRef) {
        var sharedF = this;
        var onetoOnehtmlString = ``;

        $('.oneEdit').remove();

        var oneToOneRel = window.listDetailsConfigDict.find(x => x.name === window.currentModule).listdetailsconfig.oneToOnerelations;
        if (oneToOneRel && oneToOneRel.length > 0) {
            for (var i = 0; i < oneToOneRel.length; i++) {
                onetoOnehtmlString = onetoOnehtmlString +
                    `<div module="` +
                    oneToOneRel[i].webModule +
                    `" action="Edit" class="oneEdit appbar-button white cudAction appbarDisabled">
            <span>` +
                    oneToOneRel[i].label +
                    `</span>
        </div>`;
            }
        }

        $('#' + classRef.ownContainer + ' .OneToOneRelContainer').append(onetoOnehtmlString);
        //add event handler
        $(document).ready();
        $('.oneEdit').off('click').on('click', function () {
            var selection = sharedF.getSelectedKeys();
            if (selection.length === 1) {
                sharedF.goToDetails(classRef.qsObj, detailsUrl, classRef.breadcrumbComponent, $(this).attr('module'));
            }
            else {
                sharedF.showMessage(sharedF.getWebAppTranslation("fe55"), sharedF.getWebAppTranslation("fe13"));
            }
            
        });
    }

    goToDetails(querystringObj, detailsUrl, breadcrumbComponent, module) {
        var sharedF = this;
        if (!sharedF.hasPermission(window.currentModule, "Update")) {
            sharedF.showMessage(sharedF.getWebAppTranslation("fe4"), sharedF.getWebAppTranslation("fe13"));
            return;
        }

        $("#datagridDx1Container").dxDataGrid("instance").hideColumnChooser();

        var selection = sharedF.getSelectedKeys();

        if (selection.length === 0) return;

        var ldConfig = window.listDetailsConfigDict.find(x => x.name === window.currentModule);

        if (selection.length > 1 && !ldConfig.listdetailsconfig.AllowBulkActions) {
            sharedF.showMessage("No bulk actions allowed", sharedF.getWebAppTranslation("fe13"));
            return;
        }

        if (selection.length > 100) {
            sharedF.showMessage("Maximum 100 items can be bulk edited at a time", sharedF.getWebAppTranslation("fe13"));
            return;
        }

        var hashUrl;
        var customUrl = ldConfig.listdetailsconfig.CustomEditUrl;
        if (customUrl != "") {
            window.location.hash = customUrl;
        } else {
            var qsSubstring;
            var locModule = module;
            if (locModule === "currentModule") {
                qsSubstring = '?module=' + querystringObj.module + '&mode=base&';
                locModule = window.currentModule;
            }
            else {
                qsSubstring = '?module=' + module + '&mode=onetoone&';
            }

            hashUrl = detailsUrl + qsSubstring + breadcrumbComponent.generateBreadcrumbQueryString(locModule, false) + '&ids=' + this.getSelectedKeys();
            window.location.hash = hashUrl;
        }
    }

    changeLanguage(languageCode) {
        localStorage.setItem('language', languageCode.toLowerCase());
        window.location.reload();
    }

    getLanguage() {
        var storageLanguage = localStorage.getItem('language');
        if (window.appConfig.user.language) {
            return window.appConfig.user.language;
        }
        else if (storageLanguage) {
            return storageLanguage;
        }
        else {
            return window.appConfig.defaultLanguage;
        }
    }

    getEnvironmentLogo() {
        var logoPath = "banner-logo.png";
        if (window.appConfig.environment) {
            logoPath = "banner-logo-" + window.appConfig.environment + ".png";
        }
        return logoPath;
    }

    getWarningsHtml(warningMessages) {
        var sharedF = this;
        var returnHtml = "<ul>";
        for (var i = 0; i < warningMessages.length; i++) {
            returnHtml = returnHtml + "<li>" + warningMessages[i] + "</li>";
        }
        returnHtml = returnHtml + "</ul>" + sharedF.getWebAppTranslation("fe14");

        return returnHtml;
    }
}
import router from './utils/router'
import domManager from './utils/domManager'
import sharedFunctions from './utils/sharedFunctions';

import navDx1 from './Components/navDx1'
import datagridDx1 from './Components/datagridDx1'
import detailsDx1 from './Components/detailsDx1'
import errorIlvo1 from './Components/errorIlvo1'
import loginIlvo1 from './Components/loginIlvo1'
import registerIlvo1 from './Components/registerIlvo1'
import forgotPasswordIlvo1 from './Components/forgotPasswordIlvo1'
import resetPasswordIlvo1 from './Components/resetPasswordIlvo1'
import validateAccountIlvo1 from './Components/validateAccountIlvo1'
import adminValidateAccountIlvo1 from './Components/adminValidateAccountIlvo1'
import contactIlvo1 from './Components/contactIlvo1'
import privacyIlvo1 from './Components/privacyIlvo1'


//initialize global variables
window.sharedFunctions = new sharedFunctions();
window.fwRouter = new router([], '#/errorRedirect');
window.mainContentTag = 'appContent';
window.fwApi = window.location.origin + "/api";


window.appConfig = {};
window.currentModule = '';
//window.columnData = [];
window.listDetailsConfigDict = [];

//lookupQsDict holds extra query string for the load function of the lookup
//this can be used for cascading lookups and for custom logic in the edit screen
window.lookupQsDict = [];

//object used for edit/create form
window.dataObj = {};

//initialize framework
initFramework();


function initFramework() {

    var storageLanguage = localStorage.getItem('language');
    //load appConfig.json
    $.ajax({
        method: "GET",
        url: window.fwApi + "/appconfig?language=" + storageLanguage
    })
        .done(function (result) {
            if (result.Error) {
                window.sharedFunctions.showMessage(result.Error, window.sharedFunctions.getWebAppTranslation("fe13"));
            } else {
                if (result.Result.authenticationMode === "Azure" && !result.Result.user.username) {
                    window.location.reload();
                }
                window.appConfig = result.Result;

                if (result.Result.user.language) {
                    DevExpress.localization.locale(result.Result.user.language);
                }
                else if (storageLanguage) {
                    DevExpress.localization.locale(storageLanguage);
                }
                else {
                    DevExpress.localization.locale(result.Result.defaultLanguage);
                }

                loadComponents();
                if (window.loadCustomComponents) {
                    window.loadCustomComponents();
                }
            }

        })
        .fail(function (result) {
            if (result.status === 401) {
                if (window.appType && window.appType === "PWA") {
                    window.location.reload();
                }
                else {
                    window.appConfig = { defaultUrl: '#/login', authenticationMode: 'Credentials' }

                    loadComponents();
                }

            }
            // handle request failures
        });
    ;
}

function loadComponents() {
    if (!window.appConfig) {
        window.sharedFunctions.showMessage(window.sharedFunctions.getWebAppTranslation("fe24"), window.sharedFunctions.getWebAppTranslation("fe13"));
    }

    //render main layout 
    domManager.appendToElem('body', '<div id="ilvoBody"></div>');
    domManager.appendToElem('body', '<div class="loadpanel"></div>');
    domManager.appendToElem('body', '<div class="custom-loadpanel"></div>');
    const navComponent = new navDx1('ilvoBody', 'nav1', mainContentTag);
    //navComponent.config = config;
    //if (window.appConfig.sideMenu.length > 0) {
    //    $(document).ready(navComponent.render({}, {}));
    //}
    $(document).ready(navComponent.render({}, {}));

    //instantiate page components
    var datagridComponent = new datagridDx1(mainContentTag, 'dg1', window.fwApi + '/list', window.fwApi + '/listdetailsconfig', '#/list', '#/details');
    var detailsComponent = new detailsDx1(mainContentTag, 'details1', window.fwApi + '/details', window.fwApi + '/listdetailsconfig', '#/list', false);
    //var viewComponent = new detailsDx1(mainContentTag, 'view1', window.fwApi + '/details', window.fwApi + '/listdetailsconfig', '#/list', true);
    var errorComponent = new errorIlvo1(mainContentTag, 'error1');
    var contactComponent = new contactIlvo1(mainContentTag, 'contactpage');
    var privacyComponent = new privacyIlvo1(mainContentTag, 'privacypage');

    //link urls to page components using route objects
    //route object consists of an url path and matching component
    const fwRoutes = [
        { path: '#/list', component: datagridComponent },
        { path: '#/details', component: detailsComponent },
        //{ path: '#/view', component: viewComponent },
        { path: '#/errorRedirect', component: errorComponent },
        { path: '#/contact', component: contactComponent },
        { path: '#/privacy', component: privacyComponent }
    ];

    if (window.appConfig && window.appConfig.authenticationMode === "Credentials") {
        var loginComponent = new loginIlvo1(mainContentTag, 'authentication');
        var registerComponent = new registerIlvo1(mainContentTag, 'authentication');
        var forgotPasswordComponent = new forgotPasswordIlvo1(mainContentTag, 'authentication');
        var resetPasswordComponent = new resetPasswordIlvo1(mainContentTag, 'authentication');
        var validateAccountComponent = new validateAccountIlvo1(mainContentTag, 'authentication');
        var adminValidateAccountComponent = new adminValidateAccountIlvo1(mainContentTag, 'authentication');

        fwRoutes.push({ path: '#/login', component: loginComponent });
        fwRoutes.push({ path: '#/register', component: registerComponent });
        fwRoutes.push({ path: '#/forgot-password', component: forgotPasswordComponent });
        fwRoutes.push({ path: '#/reset-password', component: resetPasswordComponent });
        fwRoutes.push({ path: '#/validate-account', component: validateAccountComponent });
        fwRoutes.push({ path: '#/admin-validate-account', component: adminValidateAccountComponent });
    }

    fwRouter.addRoutes(fwRoutes);

    //handle initial url
    $(document).ready(function () {
        const firstHashUrl = window.location.hash.substr(1);
        if (window.appConfig.authenticationMode === "Credentials" && ["/register", "/forgot-password", "/reset-password", "/validate-account", "/login", "/contact", "/privacy"].includes(firstHashUrl.split("?")[0])) {
            window.location.hash = firstHashUrl;
            window.dispatchEvent(new HashChangeEvent("hashchange"));


        }
        else if (!window.appConfig.user.username || firstHashUrl === "") {
            if (window.appConfig.defaultUrl.includes('#')) {
                window.location.hash = window.appConfig.defaultUrl;
                window.dispatchEvent(new HashChangeEvent("hashchange"));
            }
            else {
                window.location = window.appConfig.defaultUrl;
            }


        } else {
            window.location.hash = firstHashUrl;
            window.dispatchEvent(new HashChangeEvent("hashchange"));
        }
    });


    $(document).on('click', '.fw-header img', function () {
        window.location.hash = window.appConfig.defaultUrl;
    });

    window.loadPanel = $(".loadpanel").dxLoadPanel({
        shadingColor: "rgba(0,0,0,0.4)",
        position: { of: "#ilvoBody" },
        visible: false,
        showIndicator: true,
        showPane: true,
        shading: true,
        closeOnOutsideClick: false,
        onShown: function () {
            setTimeout(function () {
                loadPanel.hide();
            }, 10000);
        },
    }).dxLoadPanel("instance");
}

window.impersonate = function (user) {
    $.ajax({
        url: '../api/impersonate?user=' + user,
        type: 'GET',
    }).done(function (result) {
        location.reload();

    }).fail(function (xhr, textStatus, errorThrown) {
        showMessage(xhr.responseText, getWebAppTranslation("fe13"));
    })
        .always(function () {
            hideLoadPanel();
        });
}

window.personate = function () {
    $.ajax({
        url: '../api/personate',
        type: 'GET',
    }).done(function (result) {
        location.reload();

    }).fail(function (xhr, textStatus, errorThrown) {
        showMessage(xhr.responseText, getWebAppTranslation("fe13"));
    })
        .always(function () {
            hideLoadPanel();
        });
}



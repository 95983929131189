import domManager from '../utils/domManager'

export default class ilvoComponent {
    constructor(parentContainer, ownContainer) {
        this.parentContainer = parentContainer;
        this.ownContainer = ownContainer;
        this.htmlString = '';

        this.currUrlObj = {};
        this.lastUrlObj = {};
        //this.config = {};
        //this.data = {};

    }

    render(currUrlObj, lastUrlObj) {
        this.currUrlObj = currUrlObj;
        this.lastUrlObj = lastUrlObj;

        this.clearRenderedData();

        this.renderCoreContent(currUrlObj, lastUrlObj);

        this.loadData(currUrlObj, lastUrlObj);

        this.renderData(currUrlObj, lastUrlObj);

        this.addEventHandlers(currUrlObj, lastUrlObj);
    }

    clear() {
        domManager.hideId(this.ownContainer);
    }

    clearRenderedData() {
        
    }

    renderCoreContent() {
        domManager.renderById(this.parentContainer, this.ownContainer, this.htmlString);
    }

    loadData() { }

    renderData() { }

    addEventHandlers() { }
}


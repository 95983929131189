export default class router {
    constructor(routes, errorRedirectUrl) {
        this.routes = routes;
        this.errorRedirectUrl = errorRedirectUrl;
        
        this.lastUrlObj = { url : "", qsObj : {}};
        this.lastComponent;
        this.currentUrlObj = { url : "", qsObj : {} };
        this.currentComponent;
       
        window.addEventListener('hashchange', this.onRouteChange.bind(this));
        //window.onpopstate = this.processPopstate(event);
    }

    addRoutes(routes) {
        this.routes = this.routes.concat(routes);
    }

    onRouteChange(event) {
        this.currentUrlObj = this.getCurrentUrlObj();
        var matchedRoute = this.findMatchedRoute(this.currentUrlObj.url);

        if (!matchedRoute) {
            this.lastUrlObj = this.currentUrlObj;
            this.lastComponent = this.currentComponent;
            window.location.hash = this.errorRedirectUrl;
        }
        else {
            if (this.lastComponent) { this.lastComponent.clear(); }
            if (matchedRoute.component) {
                this.currentComponent = matchedRoute.component;
                matchedRoute.component.render(this.currentUrlObj, this.lastUrlObj);
                this.lastUrlObj = this.currentUrlObj;
                this.lastComponent = this.currentComponent;
            }
            else {
                alert("no component found");
            }
        }
    }

    findMatchedRoute(path) {
        const restring = "^" + path + "$";
        const re = new RegExp(restring, '');
        var matchedRoute = this.routes.find(r => r.path.match(re)) || undefined;
        return matchedRoute;
    }

    //returns a url object. This object contains a url string (without querystring) + query string object
    //example url: http://localhost:50421/#/list?table=Trip&year=2019
    getCurrentUrlObj() {
        var n = window.location.hash.lastIndexOf('?');
        var urlObj = {};
        if (n !== -1) {
            urlObj.url = window.location.hash.substr(0, n);
            urlObj.qs = window.location.hash.substr(n + 1);
            urlObj.qsObj = this.queryStringToJsonObj(urlObj.qs); 
        } else {
            urlObj.url = window.location.hash;
            urlObj.qsObj = {}
        }
        return urlObj;
    }
    
    queryStringToJsonObj(qs) {
        qs = qs || location.search.slice(1);

        var pairs = qs.split('&');
        var result = {};
        pairs.forEach(function (p) {
            var pair = p.split('=');
            var key = pair[0];
            var value = decodeURIComponent(pair[1] || '');

            if (result[key]) {
                if (Object.prototype.toString.call(result[key]) === '[object Array]') {
                    result[key].push(value);
                } else {
                    result[key] = [result[key], value];
                }
            } else {
                result[key] = value;
            }
        });

        return JSON.parse(JSON.stringify(result));
    }
}
import ilvoComponent from './ilvoComponent'
import domManager from '../utils/domManager'


export default class breadcrumbIlvo1 extends ilvoComponent {
    constructor(parentContainer, ownContainer, listUrl) {
        super(parentContainer, ownContainer);

        this.listUrl = listUrl;
        this.parentContainer = parentContainer;
    }

    renderData() {
        var classRef = this;

        if (!window.breadcrumb) return;
        var html = '<div class="ilvo-breadcrumb-left">';
        for (var i = 0; i < window.breadcrumb.leftItems.length; i++) {
            var addPreviousButton = false;
            var bcClass = '';
            var selectedItem = window.breadcrumb.leftItems[i].display;
            //if (i === 0) {
            //    bcClass += 'first ';
            //}
            //if (window.breadcrumb.leftItems[i].webModule === window.currentModule) {
            //    //bcClass += 'active ';
            //    var selectedData = sharedFunctions.getSelectedData();
            //    if (selectedData.length === 1) {
            //        selectedItem = selectedData[0].DisplayValue;
            //        window.breadcrumb.leftItems[i].selectedId = selectedData[0].ID;
            //    }
            //    else if (selectedData.length > 1) {
            //        selectedItem = selectedData.length + " selected";

            //    }
            //}

            //if (i === window.breadcrumb.leftItems.length - 1 && window.breadcrumb.rightItems.length === 0 && window.breadcrumb.menuItems.length === 0) {
            //    bcClass += 'last ';
            //}
            if (i === window.breadcrumb.leftItems.length - 2) {
                bcClass += 'previous ';
                addPreviousButton = true;
            }
            if (i !== window.breadcrumb.leftItems.length - 1) {
                bcClass += 'ilvo-large ';
            }

            //html += '<li module="' + window.breadcrumb.leftItems[i].webModule + '"><div class="breadcrumb-item-content ' + bcClass + '"><div class="breadcrumb-module">' + window.breadcrumb.leftItems[i].label + '</div><div class=breadcrumb-selected-item>' + selectedItem + '</div></div></li>';
            html += '<div module="' +
                window.breadcrumb.leftItems[i].webModule +
                '" class="breadcrumb-button ' + bcClass + '">' +
                '<div class="breadcrumb-button-title">' + window.breadcrumb.leftItems[i].label + '</div>' +
                '<div class="breadcrumb-button-subtitle">' + selectedItem + '</div>' +
                '</div>';
            if (addPreviousButton) {
                html += '<div module="' +
                    window.breadcrumb.leftItems[i].webModule +
                    '" class="breadcrumb-button ilvo-small">' +
                    '<span class="material-icons">arrow_back</span>' + 
                    '</div>';
            }
        }
        html += '</div><div class="ilvo-breadcrumb-right">';
        for (var j = 0; j < window.breadcrumb.rightItems.length; j++) {
            var nextClass = '';
            var style = '';
            if (window.breadcrumb.rightItems[j].isNext) {
                nextClass = 'next';
            }
            if (window.breadcrumb.rightItems[j].backgroundColor) {
                style = ' style="background-color: ' + window.breadcrumb.rightItems[j].backgroundColor + '" ';
            }
            html += '<div module="' +
                window.breadcrumb.rightItems[j].webModule + '"' + style +
                '" class="breadcrumb-button ilvo-large ' + nextClass + '">' +
                '<div class="breadcrumb-button-text">' + window.breadcrumb.rightItems[j].label + '</div>' +
                '</div>';

        }
        html += '<div id="breadcrumb-menu-items" class="breadcrumb-menu-items ilvo-large"></div>';
        html += '<div id="breadcrumb-menu-items-all" class="breadcrumb-menu-items ilvo-small"></div>';

        $('#' + classRef.parentContainer + '.breadcrumb-content').html(html);

        if (window.breadcrumb.menuItems.length > 0) {
            $('#' + classRef.parentContainer + " #breadcrumb-menu-items").dxDropDownButton({
                icon: "menu",
                dropDownOptions: {
                    width: 250
                },
                onItemClick: function (e) {
                    var module = e.itemData.webModule;
                    classRef.navigateToModule(module, true);
                },
                items: window.breadcrumb.menuItems,
                displayExpr: "label"
            });
        }

        var allSubItems = [];
        allSubItems = allSubItems.concat(window.breadcrumb.rightItems);
        allSubItems = allSubItems.concat(window.breadcrumb.menuItems);

        if (allSubItems.length > 0) {
            $('#' + classRef.parentContainer + " #breadcrumb-menu-items-all").dxDropDownButton({
                icon: "menu",
                dropDownOptions: {
                    width: 250
                },
                onItemClick: function (e) {
                    var module = e.itemData.webModule;
                    classRef.navigateToModule(module, true);
                },
                items: allSubItems,
                displayExpr: "label"
            });
        }
    }

    addEventHandlers() {
        var classRef = this;
        $('.ilvo-breadcrumb-left .breadcrumb-button').off('mousedown').on('mousedown', function (handler) { classRef.navigateToModule($(this).attr('module'), false, handler) } );
        $('.ilvo-breadcrumb-right .breadcrumb-button').off('mousedown').on('mousedown', function (handler) { classRef.navigateToModule($(this).attr('module'), true, handler) });
    }

    //event handlers
    alertNotImplemented(event) {
        alert('not yet implemented');
    }

    generateBreadcrumbQueryString(module, navigateNext) {
        var bcLength = 1;
        var breadcrumbLeftItems = [];
        if (window.breadcrumb && window.breadcrumb.leftItems) {
            breadcrumbLeftItems = window.breadcrumb.leftItems;
        }
        //if (bcLength > 1) {
        var breadcrumbQueryString = "";
        for (var i = 0; i < breadcrumbLeftItems.length; i++) {
            if (breadcrumbLeftItems[i].webModule === module) {
                break;
            }
            breadcrumbQueryString += "bc:" + breadcrumbLeftItems[i].webModule + "=" + breadcrumbLeftItems[i].selectedId + "&";
            bcLength++;
        }
        var filter = "";
        if (navigateNext) {
            filter = breadcrumbLeftItems[breadcrumbLeftItems.length - 1].webModule + "=" + breadcrumbLeftItems[breadcrumbLeftItems.length - 1].selectedId + "&";
        }
        else if (bcLength > 1) {
            filter = breadcrumbLeftItems[bcLength - 2].webModule + "=" + breadcrumbLeftItems[bcLength - 2].selectedId + "&";
        }

        var result = filter + breadcrumbQueryString;
        if (result.endsWith('&')) {
            result = result.slice(0, -1);
        }

        return result;
    }

    navigateToModule(module, navigateNext, handler) {

        if (handler && handler.which !== 1 && handler.which !== 2) {
            return;
        }
        var lastItem = window.breadcrumb.leftItems.slice(-1)[0];
        //console.log(lastItem);
        //console.log(sharedFunctions.getSelectedKeys().length);
        sharedFunctions.getSelectedKeys().length;
        if (!lastItem.selectedId && sharedFunctions.getSelectedKeys().length === 1) {
            this.render();
            lastItem = window.breadcrumb.leftItems.slice(-1)[0];
            //console.log(lastItem);
        }
        if (navigateNext && (!lastItem.selectedId || lastItem.selectedId.includes(','))) {
            sharedFunctions.showMessage(sharedFunctions.getWebAppTranslation("fe1"),
                sharedFunctions.getWebAppTranslation("fe13"));
            return;
        } else if (!sharedFunctions.hasPermission(module, "Read")) {
            sharedFunctions.showMessage(sharedFunctions.getWebAppTranslation("fe26"),
                sharedFunctions.getWebAppTranslation("fe13"));
            return;
        }
        var hashUrl = this.listUrl + '?module=' + module + '&' + this.generateBreadcrumbQueryString(module, navigateNext);
        if (handler && handler.which === 2) {
            var url = hashUrl;
            Object.assign(document.createElement('a'), { target: '_blank', href: url }).click();

        } else {
            window.location.hash = hashUrl;
        }

        //$("#d1-grid-content").dxDataGrid("instance").clearSelection();

    }
}


import ilvoComponent from './ilvoComponent'
import domManager from '../utils/domManager'


export default class privacyIlvo1 extends ilvoComponent {
    constructor(parentContainer, ownContainer, mainContentTag) {
        super(parentContainer, ownContainer);
        this.mainContentTag = mainContentTag;

        this.config = window.appConfig;
        this.sideMenu = {};

        this.htmlString = ` 
<div class="d1-privacy-page" id="${this.ownContainer}">
    <div class="fw-header">
        <div class="fw-width">
            <img src="/project/img/${sharedFunctions.getEnvironmentLogo()}" />
            <div class="user-header-info"></div>
        </div>
    </div>
    <div id="d1-body">
        <div class="fw-compact-width">
            <div id="d1-privacy-content">
	        </div>
         </div>
    </div>
</div>

        `;
    }

    render(currUrlObj, lastUrlObj) {
        this.currUrlObj = currUrlObj;
        this.lastUrlObj = lastUrlObj;

        this.clearRenderedData();

        this.renderCoreContent();

        this.loadAndRenderData();

        this.addEventHandlers();
    }

    clearRenderedData() {
        if (domManager.existsId(this.ownContainer)) {
            domManager.removeId(this.ownContainer);
        }
    }

    loadAndRenderData() {
        window.currentPage = 'privacy';

         sharedFunctions.addUserInHeader("#" + this.ownContainer + " .user-header-info");

        $("#d1-privacy-content").html(window.appConfig.privacyHtml);

    }

    addEventHandlers() {
        var classRef = this;
        $('#' + classRef.ownContainer + ' .d1-lang-item').off('click');
        $('#' + classRef.ownContainer + ' .d1-lang-item').on('click', function () { sharedFunctions.changeLanguage($(this).attr('lang')) });
    }

}
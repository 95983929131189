export default class domManager {
    constructor() {
    }

    static existsId(containerId) {
        var returnValue = $("#" + containerId).length;
        return returnValue;
    }


    static appendToElem(elem, htmlString) {
        $(elem).append(htmlString);
    }

    static appendToId(parentContainerId, htmlString) {
        $("#" + parentContainerId).append(htmlString);
    }

    static prependToId(parentContainerId, htmlString) {
        $("#" + parentContainerId).prepend(htmlString);
    }

    static showId(containerId) {
        $("#" + containerId).show();
    }

    static hideId(containerId) {
        $("#" + containerId).hide();
    }

    static removeId(containerId) {
        return $("#" + containerId).remove();
    }

    static setById(containerId, htmlString) {
        $("#" + containerId).html(htmlString);
    }

    static elemById(containerId) {
        return $('#' + containerId);
    }

    static addEventHandlerById(containerId, event, eventHandlerFn) {
        $('#' + containerId).on(event, eventHandlerFn);
    }

    static renderById(parentContainerId, childContainerId, htmlString) {
        if (!domManager.existsId(childContainerId)) {
            domManager.appendToId(parentContainerId, htmlString);
        }
        domManager.showId(childContainerId);
    }

    static addClassByClassName(className, classToAdd) {
        $("." + className).addClass(classToAdd);
    }

    static removeClassByClassName(className, classToAdd) {
        $("." + className).removeClass(classToAdd);
    }

}
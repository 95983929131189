import ilvoComponent from './ilvoComponent';
import breadcrumbIlvo1 from './breadcrumbIlvo1';
import domManager from '../utils/domManager';


export default class datagridDx1 extends ilvoComponent {
    constructor(parentContainer, ownContainer, apiUrlModule, apiUrlConfig, listUrl, detailsUrl) {
        super(parentContainer, ownContainer);
        this.apiUrlModule = apiUrlModule;
        this.apiUrlConfig = apiUrlConfig;
        this.listUrl = listUrl;
        this.detailsUrl = detailsUrl;

        this.selection = {};
        this.breadcrumbComponent = {};
        this.breadcrumbComponent = new breadcrumbIlvo1('grid-breadcrumb-container', 'grid-breadcrumb', listUrl);

        this.listDetailsConfigRequest = undefined;
        this.dataTableRequest = undefined;
        this.newRowData = [];

        var createLabel = sharedFunctions.getWebAppTranslation("create");
        var editLabel = sharedFunctions.getWebAppTranslation("edit");
        var deleteLabel = sharedFunctions.getWebAppTranslation("delete");
        var bulkSelectLabel = sharedFunctions.getWebAppTranslation("bulkselect");
        var fileLabel = sharedFunctions.getWebAppTranslation("file");

        this.htmlString = `

    <div class="datagridDx1" id="${this.ownContainer}"> 
    <div class="fw-header">
        <div class="fw-width">
            <img src="/project/img/${sharedFunctions.getEnvironmentLogo()}" />
            <div class="user-header-info"></div>
        </div>
        
    </div>
    <div class="d1-breadcrumb">
        <div class="breadcrumb-content fw-width" id="grid-breadcrumb-container"></div>
    </div>
	<div id="d1-body">
		<div id="d1-list">
        <div class="fw-width">
            <div class="d1-grid" id="d1-grid-content"></div>
        </div>
			
			<div class="d1-grid appBar">
                <div class="fw-width">
                    <div class="appbarbtnContainer appbar-left CustomActionsContainer OneToOneRelContainer">
					    <div id="btn-bulk" class="appbar-button white">
                            <span class="material-icons">list</span>
						    <span class="ilvo-large">`+ bulkSelectLabel + `</span>
					    </div>
                        <div id="btn-file" action="File" class="appbar-button white cudAction" style="display:none">
                            <span class="material-icons">launch</span>
						    <span class="ilvo-large">`+ fileLabel + `</span>
					    </div>
                        <div id="btn-custom-actions-more" class="appbar-button secondary ilvo-small ca-hidden" style="display:none;">
                            <span class="material-icons">more_vert_icon</span>
					    </div>
				    </div>
				    <div class="appbarbtnContainer appbar-right" style="float:right; margin-right:0px">
                        <div id="btn-create" action="Create" class="appbar-button green cudAction appbarDisabled">
                            <span class="material-icons">add_box</span>
						    <span class="ilvo-large">`+ createLabel + `</span>
					    </div>
					    <div id="btn-edit" module="`+ "currentModule" + `" action="Edit" class="appbar-button yellow cudAction appbarDisabled">
                            <span class="material-icons">edit</span>
						    <span class="ilvo-large">`+ editLabel + `</span>
					    </div>
					    <div id="btn-delete" action="Delete" class="appbar-button red cudAction appbarDisabled">
                            <span class="material-icons">delete</span>
						    <span class="ilvo-large">`+ deleteLabel + `</span>
					    </div>
				    </div>
                </div>
			</div>
            <div id="fw-context-menu"></div>   
		 </div>
        </div>
        `;
    }


    //custom render because of custom clearRenderedData 
    render(currUrlObj, lastUrlObj) {
        this.currUrlObj = currUrlObj;
        this.lastUrlObj = lastUrlObj;

        this.clearRenderedData();

        this.renderCoreContent();

        //loadAndRender is not split up yet
        this.loadAndRenderData();

        this.addEventHandlers();
    }

    clearRenderedData() {
        if (domManager.existsId(this.ownContainer)) {
            domManager.removeId("datagridDx1Container");
        }
    }

    sendRequest(url, method = 'GET', data) {
        const d = $.Deferred();

        $.ajax(url, {
            method,
            data,
            contentType: 'application/json',
            dataType: "json",
            cache: false,
            xhrFields: { withCredentials: true },
        }).done((result) => {
            if (result.Error) {
                d.reject(result.Error);
            }
            else if (result.Result.errorMessages && result.Result.errorMessages.length > 0) {
                d.reject(result.Result.errorMessages);
            }
            else if (result.Result.warningMessages && result.Result.warningMessages.length > 0) {
                var warningsHtml = sharedFunctions.getWarningsHtml(result.Result.warningMessages);
                var messageResult = DevExpress.ui.dialog.confirm(warningsHtml, sharedFunctions.getWebAppTranslation("fe11"));

                messageResult.done(function (dialogResult) {
                    if (dialogResult) {
                        url = url + "&ignoreWarnings=true";
                        d.resolve(url.includes('list?') ? result.Result.Items : result);
                    }
                    else {
                        d.reject(result.Result.warningMessages);
                    }
                });
            }
            else {
                d.resolve(url.includes('list?') ? result.Result.Items : result);

            }
        }).fail((xhr) => {
            //d.reject(xhr.responseJSON ? xhr.responseJSON.Message : xhr.statusText);
            d.reject("test");
        });

        return d.promise();
    }

    loadAndRenderData() {

        var classRef = this;
        if (!window.appConfig.user.username) {
            window.redirectAfterLoginObj = classRef.currUrlObj;
            sharedFunctions.logout();
            return;
        }

        window.currentPage = 'list';

        sharedFunctions.showLoadPanel();

        sharedFunctions.addUserInHeader("#" + this.ownContainer + " .user-header-info");

        window.IlvoGrid = this;
        var qsObj = this.currUrlObj.qsObj;
        var module = qsObj.module;
        this.setCurrentModule(module);

        var qsModule = new URLSearchParams(qsObj).toString();
        var locApiUrlModule = this.apiUrlModule + "?" + qsModule;

        var qsLDConfig = "module=" + module;
        var apiUrlLDConfig = this.apiUrlConfig + "?" + qsLDConfig;


        // make api calls
        this.dataTableRequest = $.ajax({
            url: locApiUrlModule,
            type: 'GET',
            context: this
        });



        // request that gets the columns/lookup data
        this.listDetailsConfigRequest = undefined;

        var listDetailsConfig = window.listDetailsConfigDict.find(x => x.name === window.currentModule);
        if (!listDetailsConfig) {
            // if the columns/lookups are already loaded, then the request is not needed
            this.listDetailsConfigRequest = $.ajax({
                url: apiUrlLDConfig,
                type: 'GET',
                context: this
            });
        }

        $.when(this.dataTableRequest, this.listDetailsConfigRequest) //wachten tot beide requests klaar zijn
            .then(function (dataTableResult, listDetailsConfigResult) {

                if (listDetailsConfigResult) {
                    if (listDetailsConfigResult[0].Error) {
                        sharedFunctions.showMessage(listDetailsConfigResult[0].Error, sharedFunctions.getWebAppTranslation("fe13"));
                        return;
                    }

                    sharedFunctions.updateListDetailsConfigDict(listDetailsConfigResult[0].Result);
                    sharedFunctions.updateLookupQsDict(listDetailsConfigResult[0].Result);
                }

                if (dataTableResult[0].Error) {
                    sharedFunctions.showMessage(dataTableResult[0].Error, sharedFunctions.getWebAppTranslation("fe13"));
                    return;
                }

                var dataTable = dataTableResult[0].Result;
                var store = dataTable.Items;
                window.breadcrumb = dataTable.Breadcrumb;
                window.permissions = dataTable.Permissions;

                var ldConfig = window.listDetailsConfigDict.find(x => x.name === window.currentModule).listdetailsconfig;
                if (ldConfig.ModuleType === "LinkTable") {
                    $(".appbarbtnContainer.appbar-right").hide();
                } else {
                    $(".appbarbtnContainer.appbar-right").show();
                }

                var updateMode = "cell";
                var allowUpdate = false;
                var allowInsert = false;
                if (sharedFunctions.hasPermission(window.currentModule, "Update") && ldConfig.ModuleType.toLowerCase() === "linktable") {
                    allowUpdate = true;
                }
                if (sharedFunctions.hasPermission(window.currentModule, "Update") && ldConfig.ModuleType.toLowerCase() === "edittable") {
                    allowUpdate = true;
                    updateMode = "row";
                }
                if (sharedFunctions.hasPermission(window.currentModule, "Create") && ldConfig.ModuleType.toLowerCase() === "edittable") {
                    allowInsert = true;
                    updateMode = "row";

                    var url = 'api/details' + '?module=' + currentModule + '&mode=base&' + classRef.breadcrumbComponent.generateBreadcrumbQueryString(currentModule, false) + "&ids=";

                    $.ajax({
                        url: url,
                        type: 'GET',
                        dataType: "json",
                        contentType: "application/json",
                    }).done(function (result) {
                        if (result.Error) {
                            sharedFunctions.showMessage(result.Error, sharedFunctions.getWebAppTranslation("fe13"));
                            return;
                        }
                        classRef.newRowData = result.Result.Item;


                    })
                        .fail(function (xhr, textStatus, errorThrown) {
                            sharedFunctions.showMessage(xhr.responseText, sharedFunctions.getWebAppTranslation("fe13"));
                        })
                }

                var allowGrouping = ldConfig.AllowGridGrouping;

                sharedFunctions.updateOneToOneRelAppBar(classRef.detailsUrl, classRef);
                classRef.updateCustomActionsAppBar();



                if (sharedFunctions.hasPermission(window.currentModule, "Create")) {
                    $('#' + classRef.ownContainer + ' #btn-create').removeClass('appbarDisabled');
                } else {
                    $('#' + classRef.ownContainer + ' #btn-create').addClass('appbarDisabled');
                }

                //show or hide the sharepoint button
                var config = window.listDetailsConfigDict.find(x => x.name === window.currentModule).listdetailsconfig;
                if (config.IsFileModule) {
                    $('#' + classRef.ownContainer + ' #btn-file').show();
                } else {
                    $('#' + classRef.ownContainer + ' #btn-file').hide();
                }

                //show or hide the bulk button
                if (config.AllowBulkActions) {
                    $('#' + classRef.ownContainer + ' #btn-bulk').show();
                } else {
                    $('#' + classRef.ownContainer + ' #btn-bulk').hide();
                }

                classRef.addEventHandlers();

                var dataGridStorageKey = module;

                var tmpCols = sharedFunctions.getColumns();
                var columns = [];
                for (var j = 0; j < tmpCols.length; j++) {
                    var newCol = {};
                    for (var p in tmpCols[j]) {
                        if (p === "lookup") {
                            if (ldConfig.ModuleType.toLowerCase() === "edittable") {
                                newCol[p] = tmpCols[j][p];
                                newCol.calculateSortValue = function (data) {
                                    const value = this.calculateCellValue(data);
                                    return this.lookup.calculateCellValue(value);
                                }
                            }
                            else {
                                // don't use lookup, relation is "translated" as text in backend
                                newCol[p] = null;
                            }
                        } else {
                            newCol[p] = tmpCols[j][p];
                        }

                    }
                    columns.push(newCol);
                }

                $("#d1-grid-content").append('<div id="datagridDx1Container"/>');

                var toolbarItems = [];

                if (ldConfig.ModuleType.toLowerCase() === "edittable") {
                    store = new DevExpress.data.CustomStore({
                        key: 'ID',
                        loadMode: 'raw',
                        cacheRawData: true,
                        load() {
                            return classRef.sendRequest(locApiUrlModule);
                        },
                        insert(values) {
                            this.clearRawDataCache();
                            values["ID"] = null;
                            return classRef.sendRequest('api/details/save?module=' + currentModule + '&mode=base&' + classRef.breadcrumbComponent.generateBreadcrumbQueryString(window.currentModule, false), 'POST', JSON.stringify(values));
                        },
                        update(key, values) {
                            this.clearRawDataCache();
                            values["ID"] = key;
                            return classRef.sendRequest('api/details/save?module=' + currentModule + '&mode=base&' + classRef.breadcrumbComponent.generateBreadcrumbQueryString(window.currentModule, false), 'POST', JSON.stringify(values));
                        },
                        remove(key) {
                            this.clearRawDataCache();
                            return classRef.sendRequest('api/delete?module=' + currentModule + '&' + classRef.breadcrumbComponent.generateBreadcrumbQueryString(window.currentModule, false) + '&ids=' + key);
                        },
                    });

                    if (allowInsert) {
                        toolbarItems.push('addRowButton');
                    }

                }


                if (ldConfig.ModuleType.toLowerCase() === "dbtable" && window.appConfig.hasAudit) {
                    toolbarItems.push({
                        location: 'after',
                        widget: 'dxButton',
                        options: {
                            icon: 'clock',
                            onClick() {
                                classRef.viewHistory();
                            },
                        },
                    });
                }
                toolbarItems.push('exportButton');
                toolbarItems.push('columnChooserButton');
                toolbarItems.push('searchPanel');
                toolbarItems.push('groupPanel');


                $("#datagridDx1Container").dxDataGrid({
                    dataSource: store,
                    //remoteOperations: true,
                    //scrolling: {
                    //    mode: "virtual",
                    //    rowRenderingMode: "virtual"
                    //},
                    allowColumnReordering: true,
                    allowColumnResizing: true,
                    cacheEnabled: false,
                    columns: columns,
                    columnAutoWidth: true,
                    columnChooser: {
                        allowSearch: false,
                        //emptyPanelText: ccTextLabel,
                        enabled: true,
                        height: 350,
                        mode: "select",
                        searchTimeout: 500,
                        //title: ccTitleLabel,
                        sortOrder: 'asc',
                        width: 250
                    },
                    editing: {
                        mode: updateMode,
                        allowUpdating: allowUpdate,
                        allowDeleting: false,
                        allowAdding: allowInsert,
                        useIcons: true
                    },
                    export: {
                        allowExportSelectedData: true,
                        enabled: true
                    },
                    grouping: {
                        autoExpandAll: true,
                    },
                    groupPanel: {
                        visible: allowGrouping,
                    },
                    headerFilter: {
                        allowSearch: true,
                        visible: true,
                    },
                    keyExpr: 'ID',
                    loadPanel: {
                        enabled: false
                    },
                    pager: {
                        showPageSizeSelector: true,
                        allowedPageSizes: [10, 15, 20, 25, 30, 50, 100, 'all'],
                        showInfo: true,
                        visible: true
                    },
                    paging: {
                        pageSize: 20
                    },
                    rowAlternationEnabled: true,
                    searchPanel: {
                        highlightCaseSensitive: false,
                        highlightSearchText: true,
                        //placeholder: searchLabel,
                        searchVisibleColumnsOnly: false,
                        text: "",
                        visible: true,
                        width: 160
                    },
                    selection: {
                        mode: "single",
                        selectAllMode: "page" // top checkbox in bulk mode selects all rows on the current page instead of all rows in the grid
                    },
                    showBorders: true,
                    showRowLines: true,
                    sorting: {
                        mode: "multiple"
                    },
                    stateStoring: {
                        enabled: true,
                        savingTimeout: 30,
                        storageKey: dataGridStorageKey + "Settings" + window.appConfig.storageKeyVersion
                    },
                    toolbar: {
                        items: toolbarItems
                    },
                    onContentReady: function (e) {

                        classRef.dxdgContentReady();

                    },
                    onRowDblClick: function (e) {

                        //navigateToNextTable(e);

                    },
                    onSelectionChanged: function (e) {
                        classRef.dxdgSelectionChanged();
                    },
                    onInitialized: function (e) {


                    },
                    onRowDblClick: function (e) {
                        classRef.navigateToNextModule();
                    },
                    onRowUpdating: function (e) {
                        classRef.updateData(e);

                    },
                    onContextMenuPreparing: function (e) {
                        classRef.showContextMenu(e);

                    },
                    onInitNewRow: function (e) {

                        for (var i = 0; i < classRef.newRowData.length; i++) {
                            e.data[classRef.newRowData[i].name] = classRef.newRowData[i].value;
                        }


                    },
                    onRowValidating: function (e) {

                        if (!e.errorText && e.brokenRules.length > 0) {
                            e.errorText = e.brokenRules[0].message;
                        }
                    }

                })
                sharedFunctions.hideLoadPanel();

            }.bind(this), function (jqXHR, textStatus, errorThrown) {

                sharedFunctions.hideLoadPanel();

                if (jqXHR.status === 401) {
                    
                    sharedFunctions.logout();

                } else {
                    sharedFunctions.showMessage(textStatus, sharedFunctions.getWebAppTranslation("fe13"));
                }
            });
    }




    //event handlers
    dxdgSelectionChanged() {

        var selectedData = sharedFunctions.getSelectedData();

        if (selectedData.length === 1) {
            window.breadcrumb.leftItems[window.breadcrumb.leftItems.length - 1].selectedId = selectedData[0].ID;
            window.breadcrumb.leftItems[window.breadcrumb.leftItems.length - 1].display = selectedData[0].DisplayValue;
        }
        else if (selectedData.length > 1) {
            window.breadcrumb.leftItems[window.breadcrumb.leftItems.length - 1].selectedId = null;
            window.breadcrumb.leftItems[window.breadcrumb.leftItems.length - 1].display = selectedData.length + " selected";
        }
        this.updateBreadCrumb();


        if (selectedData.length === 0) {
            $('#' + this.ownContainer + ' .cudAction').addClass('appbarDisabled');
        }
        // else: if multiple rows are selected
        else {
            if (sharedFunctions.hasPermission(window.currentModule, "Update")) {
                $('#' + this.ownContainer + ' #btn-edit').removeClass('appbarDisabled');
            } else {
                $('#' + this.ownContainer + ' #btn-edit').addClass('appbarDisabled');
            }
            if (sharedFunctions.hasPermission(window.currentModule, "Delete")) {
                $('#' + this.ownContainer + ' #btn-delete').removeClass('appbarDisabled');
            } else {
                $('#' + this.ownContainer + ' #btn-delete').addClass('appbarDisabled');
            }
            if (selectedData.length === 1) {
                $('#' + this.ownContainer + ' #btn-file').removeClass('appbarDisabled');
            } else {
                $('#' + this.ownContainer + ' #btn-file').addClass('appbarDisabled');
            }
        }

        if (sharedFunctions.hasPermission(window.currentModule, "Create")) {
            $('#' + this.ownContainer + ' #btn-create').removeClass('appbarDisabled');
        } else {
            $('#' + this.ownContainer + ' #btn-create').addClass('appbarDisabled');
        }

        var oneToOneRel = window.listDetailsConfigDict.find(x => x.name === window.currentModule).listdetailsconfig.oneToOnerelations;
        for (var i = 0; i < oneToOneRel.length; i++) {
            if (sharedFunctions.hasPermission(oneToOneRel[i].webModule, "Create")) {
                $('#' + this.ownContainer + ' div[module=' + oneToOneRel[i].webModule + '].oneEdit').removeClass('appbarDisabled');
            } else {
                $('#' + this.ownContainer + ' div[module=' + oneToOneRel[i].webModule + '].oneEdit').addClass('appbarDisabled');
            }
        }
    }


    dxdgContentReady(e) {
        var expired = new Date().getTime() - window.timer;

        this.dxdgSelectionChanged();

        this.updateBreadCrumb();

        if (window.listPageRendered && window.lastListPageRender !== currentModule) {
            window.lastListPageRender = currentModule;
            window.listPageRendered(window.currentModule);
        }
    }

    showContextMenu(e) {

        if (e.row === undefined) return;

        if (!e.items) e.items = [];

        if (e.row.rowType === "data") {
            // Add a custom menu item
            var allFields = { text: "Copy ", items: [] };

            for (var i = 0; i < e.row.cells.length; i++) {
                if (e.row.cells[i].column.showInColumnChooser) {
                    var itemText = e.row.cells[i].column.caption;
                    var itemKey = e.row.cells[i].column.dataField;
                    allFields.items.push({
                        text: itemText,
                        key: itemKey,
                        onItemClick: function (item) {
                            var key = item.itemData.key;
                            var text = item.itemData.text;
                            if (e.row.data[key]) {
                                /* Get the text field */
                                var copyText = e.row.data[key];

                                var $temp = $("<input>");
                                $("body").append($temp);
                                $temp.val(copyText).select();
                                document.execCommand("copy");
                                $temp.remove();
                                sharedFunctions.showToast(text + " copied to clipboard", "success", 1500);
                            }
                            else {
                                sharedFunctions.showMessage('Value is empty. Cannot copy this value to the clipboard', 'Empty value');
                            }
                        }
                    });
                }
            }

            e.items.push(allFields);
        }
    }
   
    updateCustomActionsAppBar() {
        var classRef = this;
        var customActionsHtmlString = ``;

        $('.customaction').remove();
        $("#btn-custom-actions-more").addClass("ca-hidden");


        var customActions = window.listDetailsConfigDict.find(x => x.name === window.currentModule).listdetailsconfig.customActions;
        if (customActions && customActions.length > 0) {
            $("#btn-custom-actions-more").removeClass("ca-hidden");
            var customItems = [];
            for (var i = 0; i < customActions.length; i++) {
                var disabledClass = 'appbarDisabled';

                if (sharedFunctions.hasPermission(window.currentModule, customActions[i].Name)) {
                    disabledClass = '';
                }
                customActionsHtmlString = customActionsHtmlString +
                    `<div eventString="` +
                    customActions[i].EventString +
                    `" class="customaction appbar-button secondary ` +
                    disabledClass +
                    `">
            
            <span>` +
                    customActions[i].Label +
                    `</span>
        </div>`;
                
            }
        }

        $('#' + classRef.ownContainer + ' .CustomActionsContainer').append(customActionsHtmlString);
        //add event handler
        //$(document).ready();
        $('#' + classRef.ownContainer + ' .customaction').off('click');
        $('#' + classRef.ownContainer + ' .customaction:not(.appbarDisabled)').on('click', function () {
            classRef.handleCustomActions($(this).attr('eventString'));
        });
    }

    addEventHandlers() {
        var classRef = this;
        $('#' + classRef.ownContainer + ' #btn-create').off('click');
        if (sharedFunctions.hasPermission(window.currentModule, "Create")) {
            $('#' + classRef.ownContainer + ' #btn-create').on('click', this.goToCreate.bind(this));
        }
        $('#' + classRef.ownContainer + ' #btn-edit').off('click');
        if (sharedFunctions.hasPermission(window.currentModule, "Update")) {
            $('#' + classRef.ownContainer + ' #btn-edit').on('click', function () { sharedFunctions.goToDetails(classRef.currUrlObj.qsObj, classRef.detailsUrl, classRef.breadcrumbComponent, $(this).attr('module')) });
        }
        $('#' + classRef.ownContainer + ' #btn-delete').off('click');
        if (sharedFunctions.hasPermission(window.currentModule, "Delete")) {
            $('#' + classRef.ownContainer + ' #btn-delete').on('click', this.deleteDialog.bind(this));
        }
        $('#' + classRef.ownContainer + ' #btn-file').off('click').on('click', this.openFileLocation.bind(this));
        $('#' + classRef.ownContainer + ' #btn-bulk').off('click').on('click', this.toggleBulkMode.bind(this));

        $('#' + classRef.ownContainer + ' #btn-custom-actions-more').off('click').on('click', this.openCustomActionMenu.bind(this));
    }

    handleCustomActions(eventString) {
        //dispatch event to custom logic 
        window.dispatchEvent(new CustomEvent("customAction-" + eventString), this);
    }

    deleteDialog() {
        if (!sharedFunctions.hasPermission(window.currentModule, "Delete")) {
            return;
        }
        var selection = sharedFunctions.getSelectedKeys();

        if (selection.length === 0) return;

        var classRef = this;
        var result = DevExpress.ui.dialog.confirm(sharedFunctions.getWebAppTranslation("fe2"), sharedFunctions.getWebAppTranslation("fe3"));
        result.done(function (dialogResult) {
            if (dialogResult) classRef.deleteSelection();
        });
    }

    deleteSelection() {
        if (!sharedFunctions.hasPermission(window.currentModule, "Delete")) {
            return;
        }
        var classRef = this;
        var selection = sharedFunctions.getSelectedKeys();

        if (selection.length === 0) return;

        var deleteUrl;
        var ldConfig = window.listDetailsConfigDict.find(x => x.name === window.currentModule);
        var customUrl = ldConfig.listdetailsconfig.CustomDeleteApiUrl;
        if (customUrl != "") {
            deleteUrl = customUrl;
        } else {
            deleteUrl = '../api/delete?module=' + currentModule + '&' + this.breadcrumbComponent.generateBreadcrumbQueryString(window.currentModule, false) + '&ids=' + sharedFunctions.getSelectedKeys();
        }

        $.ajax({
            url: deleteUrl,
            type: 'GET',
            dataType: "json",
            contentType: "application/json",
        }).done(function (result) {
            if (result.Error) {
                sharedFunctions.showMessage(result.Error, sharedFunctions.getWebAppTranslation("fe13"));
                return;
            }
            window.lastListPageRender = '';
            classRef.render(classRef.currUrlObj, classRef.lastUrlObj);

        }).fail(function (xhr, textStatus, errorThrown) {
            sharedFunctions.showMessage(xhr.responseText, sharedFunctions.getWebAppTranslation("fe13"));
        }).always(function () {
            //alert("complete");
        });
    }


    goToCreate() {
        if (!sharedFunctions.hasPermission(window.currentModule, "Create")) {
            return;
        }

        $("#datagridDx1Container").dxDataGrid("instance").hideColumnChooser();

        var hashUrl;
        var ldConfig = window.listDetailsConfigDict.find(x => x.name === window.currentModule);
        var customUrl = ldConfig.listdetailsconfig.CustomCreateUrl;
        if (customUrl != "") {
            window.location.hash = customUrl;
        } else {
            var qsObj = this.currUrlObj.qsObj;
            var module = qsObj.module;
            hashUrl = this.detailsUrl + '?module=' + module + '&mode=base&' + this.breadcrumbComponent.generateBreadcrumbQueryString(module, false) + "&ids=";

            window.location.hash = hashUrl;
        }
    }

    navigateToNextModule(e) {
        if (!window.breadcrumb) return;
        var nextItem = window.breadcrumb.rightItems.find(x => x.isNext === true);

        if (!nextItem) {
            nextItem = window.breadcrumb.menuItems.find(x => x.isNext === true);
        }
        if (nextItem) {
            this.breadcrumbComponent.navigateToModule(nextItem.webModule, true);

        }
    }

    setCurrentModule(module) {
        if (window.currentModule !== module) {
            window.currentModule = module;
        }
    }

    updateData(e) {
        if (!sharedFunctions.hasPermission(window.currentModule, "Update")) {
            return;
        }

        var url = '../api/list/link?module=' + window.currentModule + '&' + this.breadcrumbComponent.generateBreadcrumbQueryString(window.currentModule, false);

        var ldConfig = window.listDetailsConfigDict.find(x => x.name === window.currentModule).listdetailsconfig;
        if (ldConfig.ModuleType !== "LinkTable") {
            return;
        }

        var dataObj = {
            id: e.key,
            selected: e.newData.Selected
        }

        $.ajax({
            url: url,
            type: 'POST',
            contentType: 'application/json',
            dataType: "json",
            data: JSON.stringify(dataObj)
        }).done(function (result) {
            if (result.Error != null) {
                sharedFunctions.showMessage(result.Error, "todo");
                e.cancel = true;
            }

        }

        ).fail(function (xhr, textStatus, errorThrown) {
            sharedFunctions.showMessage(xhr.responseText, sharedFunctions.getWebAppTranslation("fe13"));
            if (result.status === 401) {
                sharedFunctions.logout();
            }
            e.cancel = true;
        });

    }

    toggleBulkMode(event) {
        var isSingleMode = $("#datagridDx1Container").dxDataGrid("option", "selection.mode") === 'single' || $("#datagridDx1Container").dxDataGrid("option", "selection.mode") === 'none';
        if (isSingleMode) {
            $("#datagridDx1Container").dxDataGrid("option", "selection.mode", "multiple");
        }
        else {
            $("#datagridDx1Container").dxDataGrid("option", "selection.mode", "single");
        }
    }

    openFileLocation(event) {
        var keys = sharedFunctions.getSelectedKeys();
        if (keys.length === 1) {
            var sharePointControllerUrl = '../api/getfileurl?module=' + currentModule + '&' + this.breadcrumbComponent.generateBreadcrumbQueryString(window.currentModule, false) + '&ids=' + keys;

            sharedFunctions.showLoadPanel();

            $.ajax({
                url: sharePointControllerUrl,
                type: 'GET',
            }).done(function (result) {
                if (result.Error) {
                    sharedFunctions.showMessage(result.Error, sharedFunctions.getWebAppTranslation("fe13"));
                    return;
                }
                var url = result.Result;
                Object.assign(document.createElement('a'), { target: '_blank', href: url }).click();

            }).fail(function (xhr, textStatus, errorThrown) {
                sharedFunctions.showMessage(xhr.responseText, sharedFunctions.getWebAppTranslation("fe13"));
            }).always(function () {
                sharedFunctions.hideLoadPanel();
            });
        }
    }

    updateBreadCrumb() {
        this.breadcrumbComponent.render();
    }

    openCustomActionMenu(event) {
        alert("Feature coming soon");
    }

    viewHistory() {
        var keys = sharedFunctions.getSelectedKeys();

        if (keys.length > 0) {
            var historyUrl = '../api/history?currentModule=' + currentModule;

            sharedFunctions.showLoadPanel();

            $.ajax({
                url: historyUrl,
                type: 'POST',
                data: JSON.stringify(keys),
                contentType: 'application/json',
                dataType: "json"
            }).done(function (result) {
                if (result.Error) {
                    sharedFunctions.showMessage(result.Error, sharedFunctions.getWebAppTranslation("fe13"));
                    return;
                } else {
                    var items = result.Result;
                    var cols = [{ dataField: "AuditDate", dataType: "datetime", format: "dd/MM/yyyy HH:mm:ss", caption: "Date" }, { dataField: "AuditType", caption: "Type" }, { dataField: "AuditUser", caption: "User" }];
                    if (items.length === 0) {
                        sharedFunctions.showMessage("No history found", "Audit");
                        return;
                    }
                    else {
                        for (var i = 0; i < items.length; i++) {
                            items[i]["isChanged-fw"] = "";
                        }
                        items[items.length - 1]["isChanged-fw"] = "initial-fw";
                    }
                    Object.keys(items[0]).forEach(function (key, index) {
                        for (var i = 0; i < items.length; i++) {
                            if (key === "isChanged-fw") {
                                continue;
                            }

                            if (i + 1 == items.length) {
                            }
                            else if (items[i][key] != items[i + 1][key] && !["AuditDate", "AuditType", "AuditUser"].includes(key)) {
                                items[i]["isChanged-fw"] += "[" + key + "]";
                                var moduleCols = window.listDetailsConfigDict.find(x => x.name === currentModule).listdetailsconfig.columns;
                                var col = moduleCols.find(x => x.dataField === key);
                                if (col) {
                                    col.filterValues = [];

                                    if (cols.filter(e => e.dataField === key).length > 0) {

                                    }
                                    else if (col.colorColumn) {
                                        cols.push({ dataField: col.dataField, caption: col.caption });
                                    }
                                    else {
                                        col.visible = true;
                                        col.lookup = null;
                                        cols.push(col);
                                    }
                                }
                            }
                        }
                    });

                    items = items.filter(e => e["isChanged-fw"] && e["isChanged-fw"].length > 0);

                    var myDialog = DevExpress.ui.dialog.custom({
                        title: "Audit",
                        messageHtml: "<div id='audit-grid'>",
                        buttons: [
                            {
                                text: sharedFunctions.getWebAppTranslation("close"),
                                onClick: function (e) {
                                    myDialog.hide();
                                }
                            },
                        ]
                    });

                    myDialog.show();


                    $("#audit-grid").dxDataGrid(
                        {
                            dataSource: items,
                            allowColumnReordering: true,
                            allowColumnResizing: true,
                            columns: cols,
                            columnAutoWidth: true,
                            width: window.innerWidth - 80,
                            keyExpr: 'AuditDate',
                            loadPanel: {
                                enabled: false
                            },
                            onCellPrepared(options) {
                                var fieldCol = options.column.dataField;

                                if (options.rowType === "data" && options.data && options.data["isChanged-fw"] && options.data["isChanged-fw"].includes("[" + fieldCol + "]")) {
                                    options.cellElement.addClass('changed-fw');
                                }
                            },
                            pager: {
                                showPageSizeSelector: true,
                                allowedPageSizes: [10, 15, 20, 25, 30, 50, 100],
                                showInfo: true
                            },
                            paging: {
                                pageSize: 20
                            },

                            rowAlternationEnabled: true,

                            selection: {
                                mode: "single",
                                //selectAllMode: "page" // top checkbox in bulk mode selects all rows on the current page instead of all rows in the grid
                            },
                            showBorders: true,
                            showRowLines: true,
                            sorting: {
                                mode: "multiple"
                            },
                            stateStoring: {
                                enabled: false,
                                savingTimeout: 30,
                            },


                        });

                }

            }).fail(function (xhr, textStatus, errorThrown) {
                sharedFunctions.showMessage(xhr.responseText, sharedFunctions.getWebAppTranslation("fe13"));
            })
                .always(function () {
                    sharedFunctions.hideLoadPanel();
                });


        } else {
            sharedFunctions.showMessage("Please select exactly 1 record", sharedFunctions.getWebAppTranslation("fe13")); // todo translation
        }
    }
}




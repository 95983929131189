import ilvoComponent from './ilvoComponent';
import domManager from '../utils/domManager';
import breadcrumbIlvo1 from './breadcrumbIlvo1';



export default class detailsDx1 extends ilvoComponent {
    constructor(parentContainer, ownContainer, apiUrl, apiUrlConfig, listUrl, readOnly) {
        super(parentContainer, ownContainer);
        this.apiUrl = apiUrl;
        this.apiUrlConfig = apiUrlConfig;
        this.listUrl = listUrl;
        this.readOnly = readOnly;

        this.breadcrumbComponent = {};
        this.data = {};
        this.columns = [];
        this.controls = [];
        this.selectionList = [];
        this.numberOfItems = 0;
        this.action = 'Update';
        this.isChanged = false;
        this.urlAfterSave = "";

        //this.columnsRequest = undefined;
        this.listDetailsConfigRequest = undefined;
        this.detailsRequest = undefined;
        this.breadcrumbComponent = new breadcrumbIlvo1('details-breadcrumb-container', 'details-breadcrumb', listUrl);

        var saveLabel = sharedFunctions.getWebAppTranslation("save");
        var closeLabel = sharedFunctions.getWebAppTranslation("close");
        var previousLabel = sharedFunctions.getWebAppTranslation("previous");
        var nextLabel = sharedFunctions.getWebAppTranslation("next");

        this.htmlString = ` 

         <div class="DetailsDx1" id="${this.ownContainer}">
    <div class="fw-header">
        <div class="fw-width">
            <img id="project-logo" src="/project/img/${sharedFunctions.getEnvironmentLogo()}" />
            <div class="user-header-info"></div>
        </div>
    </div>
            <div class="d1-breadcrumb">
        <div class="breadcrumb-content fw-width" id="details-breadcrumb-container"></div>
    </div>
	        <div class="d1-details fw-width" id="d1-details-content">
                    <div id="details-list"></div>
                    <div id="details-form"></div>
            </div>
		    <div class="d1-details appBar">
                <div class="fw-width">
                    <div class="appbarbtnContainer appbar-left OneToOneRelContainer"></div>
			<div class="appbarbtnContainer appbar-center">
                <div id="btn-previous" action="Previous" class="appbar-button white cudAction fw-nav-btn">
                        <span class="material-icons" style="transform: scale(-1, 1); top: 0;">play_circle</span>
					    <span class="ilvo-large">` +
            previousLabel +
            `</span>
				</div>
                <div id="btn-next" action="Next" class="appbar-button white cudAction fw-nav-btn">
					    <span class="ilvo-large">` +
            nextLabel +
            `</span>
            <span class="material-icons">play_circle</span>
				</div>
			</div>
        <div class="appbarbtnContainer appbar-right">`;

        if (!readOnly) {
            this.htmlString +=
                `<div id="btn-save" action="Save" class="appbar-button green cudAction appbarDisabled">
                        <span class="material-icons">save</span>
					<span class="ilvo-large">` +
                saveLabel +
                `</span>
				</div>`;
        }

        this.htmlString += `<div id="btn-close" action="Close" class="appbar-button white cudAction">
                        <span class="material-icons">cancel</span>
					<span class="ilvo-large">`+ closeLabel + `</span>
				</div>
			</div>
		</div>
		</div>
        </div>
        `;


    }

    loadData() {

        var classRef = this;
        if (!window.appConfig.user.username) {
            window.redirectAfterLoginObj = classRef.currUrlObj;
            sharedFunctions.logout();
            return;
        }

        window.currentPage = 'details';
        window.lastListPageRender = '';
        sharedFunctions.showLoadPanel();

         sharedFunctions.addUserInHeader("#" + this.ownContainer + " .user-header-info");

        var qsObj = this.currUrlObj.qsObj;
        var module = qsObj.module;
        this.setCurrentModule(module);
        this.isChanged = false;

        //build api urls
        var qsDetails = new URLSearchParams(qsObj).toString();
        //var qsColumns = "table=" + module;
        var qsLDConfig = "module=" + module;

        //var locApiUrlColumns = this.apiUrlColumns + "?" + qsColumns;
        var apiUrlLDConfig = this.apiUrlConfig + "?" + qsLDConfig;

        this.detailsRequest = $.ajax({
            method: "GET",
            url: this.apiUrl + "?" + qsDetails,
            context: this,
        });

        // request that gets the columns/lookup data
        //this.columnsRequest = undefined;
        this.listDetailsConfigRequest = undefined;

        //var columnConfig = window.columnData.find(x => x.name === window.currentModule);
        //if (!columnConfig) {
        //    // if the columns/lookups are already loaded, then the request is not needed
        //    this.columnsRequest = $.ajax({
        //        url: locApiUrlColumns,
        //        type: 'GET',
        //        context: this
        //    });
        //}

        var listDetailsConfig = window.listDetailsConfigDict.find(x => x.name === window.currentModule);
        if (!listDetailsConfig) {
            // if the listdetailsconfig is are already loaded for this table, then the request is not needed
            this.listDetailsConfigRequest = $.ajax({
                url: apiUrlLDConfig,
                type: 'GET',
                context: this
            });
        }
    }

    renderData() {


        var classRef = this;
        $("#" + classRef.ownContainer + " #d1-details-content").html(
            '<div id="details-list"></div><div id="details-form"><div id="details-form-container"></div><div id="summary"></div></div>');

        $.when(this.detailsRequest, this.listDetailsConfigRequest) //wachten tot beide requests klaar zijn
            .then(function(detailsResult, listDetailsConfigResult) {
                    if (listDetailsConfigResult) {
                        if (listDetailsConfigResult[0].Error) {
                            sharedFunctions.showMessage(listDetailsConfigResult[0].Error,
                                sharedFunctions.getWebAppTranslation("fe13"));
                            return;
                        }

                        sharedFunctions.updateListDetailsConfigDict(listDetailsConfigResult[0].Result);
                        sharedFunctions.updateLookupQsDict(listDetailsConfigResult[0].Result);
                    }


                    if (detailsResult[0].Error) {
                        sharedFunctions.showMessage(detailsResult[0].Error,
                            sharedFunctions.getWebAppTranslation("fe13"));
                        sharedFunctions.navigateToUrl(classRef.generateListUrl());
                        return;
                    }

                    classRef.controls = [];
                    classRef.selectionList = [];
                    classRef.data = detailsResult[0].Result;
                    //var test = { name: "TestFile", displayName: "TestFile", type: "File", isRequired: false, isVisible: true, maxLength: null, value: "" };
                    //classRef.data.Item.push(test);
                    //classRef.data.Item.splice(6, 0, test)
                    window.breadcrumb = detailsResult[0].Result.Breadcrumb;
                    window.permissions = detailsResult[0].Result.Permissions;

                    if (sharedFunctions.hasPermission(window.currentModule, "Update")) {
                        $('#' + classRef.ownContainer + ' #btn-save').removeClass('appbarDisabled');
                    }

                    //var columns = sharedFunctions.getColumnsOld(columnsResult);
                    var columns = sharedFunctions.getColumns();

                    window.dataObj = {};

                    // controls is an array of form items.
                    // see https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxForm/Item_Types/SimpleItem/

                    var details = classRef.data.Item;

                    classRef.numberOfItems = 1;

                    if (!classRef.currUrlObj.qsObj.ids || classRef.currUrlObj.qsObj.ids === "") {
                        classRef.action = "Create";
                    } else {
                        classRef.numberOfItems = classRef.currUrlObj.qsObj.ids.split(',').length;
                        classRef.action = "Update";
                    }

                    if (classRef.action === "Update" && classRef.numberOfItems === 1) {
                        $('#' + classRef.ownContainer + ' #btn-previous').show();
                        $('#' + classRef.ownContainer + ' #btn-next').show();

                        sharedFunctions.updateOneToOneRelAppBar("#/details", classRef);
                        $('#' + classRef.ownContainer + ' .oneEdit').removeClass('appbarDisabled');
                    } else {
                        $('#' + classRef.ownContainer + ' #btn-previous').hide();
                        $('#' + classRef.ownContainer + ' #btn-next').hide();
                    }

                    if (classRef.currUrlObj.qsObj.mode === 'navToPrev' ||
                        classRef.currUrlObj.qsObj.mode === 'onetoone') {
                        $('#' + classRef.ownContainer + ' #btn-previous').hide();
                        $('#' + classRef.ownContainer + ' #btn-next').hide();
                    }
                    //clear all non nullable values when bulk editing

                    var parent = null;
                    if (window.breadcrumb.leftItems.length > 1) {
                        parent = window.breadcrumb.leftItems[window.breadcrumb.leftItems.length - 2];
                    }

                    //if (classRef.numberOfItems > 1 || this.action === "Create") {
                    //    for (var i = 0; i < classRef.data.Item.length; i++) {
                    //        if (parent == null || classRef.data.Item[i].value !== parent.selectedId) {
                    //            if (window.currentModule.toLowerCase() === "translation" && (
                    //                classRef.data.Item[i].name.toLowerCase() === "uniqueidentifier" || classRef.data.Item[i].name.toLowerCase() === "namespace")) {
                    //                // this is allowed
                    //            } else {
                    //                classRef.data.Item[i].value = null;
                    //            }
                    //        }
                    //    }
                    //}

                    var disabledItems = classRef.readOnly /*|| classRef.numberOfItems > 1*/;

                    var item = {};

                    // only group if the item contains groups and if not bulk edit
                    var workGrouped = details.find(x => x.group !== null) !== undefined && classRef.numberOfItems <= 1;

                    for (var j = 0; j < details.length; j++) {
                        item = {};

                        var columnElem = columns.find(x => x.dataField === details[j].name);
                        var isVisible = classRef.numberOfItems <= 1 && details[j].isVisible;

                        if (details[j].isRequired) {
                            columnElem.dxErrorRules.push({ type: 'required' });
                        }


                        if (details[j].name !== 'ID' && details[j].isVisible && !disabledItems && !details[j].isDisabled
                        ) { // add other logic that hides the property
                            classRef.selectionList.push(details[j].displayName);
                        }

                        window.dataObj[details[j].name] = details[j].value;

                        if (details[j].name === 'ID') {
                            item = {
                                dataField: details[j].name,
                                label: {
                                    text: details[j].displayName
                                },
                                isRequired: details[j].isRequired,
                                editorType: "dxTextBox",
                                editorOptions: {
                                    showClearButton: true,
                                    disabled: disabledItems || details[j].isDisabled,
                                },
                                helpText: details[j].info,
                                visible: false
                            };

                            continue;
                        }
                        if (columnElem.lookup) {
                            //var lookup = columns.find(x => x.caption === details[j].displayName).lookup;
                            var lookup = columnElem.lookup;

                            //store parent key value in lookupQsDict, if necessary
                            var lookupQsElem = window.lookupQsDict.filter(x => x.module === window.currentModule &&
                                x.parentLookup === details[j].name);
                            if (lookupQsElem.length > 0) {
                                for (var k = 0; k < lookupQsElem.length; k++) {
                                    lookupQsElem[k].parentQs = "&" + lookupQsElem[k].parentLookup + "=" + details[j].value;
                                }

                            }

                            //code to hide the parent
                            //if (window.breadcrumb.leftItems.length > 1) {
                            //    if (parent.selectedId === details[j].value) {
                            //        isVisible = false;
                            //    }
                            //}
                            item = {
                                dataField: details[j].name,
                                label: {
                                    text: details[j].displayName
                                },
                                isRequired: details[j].isRequired,
                                editorType: "dxLookup",
                                //helpText: "hallo testje",
                                editorOptions: {
                                    dataSource: lookup.dataSource,
                                    //dataSource: function (options) {
                                    //    return ds;
                                    //    //{
                                    //    //    store: lookup.dataSource.store
                                    //    //    ,
                                    //    //    filter: options.data ? ["StateID", "=", options.data.StateID] : null
                                    //    //};
                                    //},
                                    // dataSource: this.getDataSource(details[j].displayName),
                                    displayExpr: 'Display',
                                    valueExpr: 'ID',
                                    //paginate: true,
                                    //pageSize: 20,
                                    dropDownOptions: {
                                        closeOnOutsideClick: true
                                    },
                                    showClearButton: true,
                                    disabled: disabledItems || details[j].isDisabled,
                                    //onValueChanged: function (e) {
                                    //    console.log("value changed!!!")
                                    //    alert('test event handling');
                                    //}

                                },
                                helpText: details[j].info,
                                visible: isVisible

                            };

                            //$("#" + details[j].name).dxLookup(lookup);
                            //var tmp = $("#" + details[j].name).dxLookup("instance");
                            //tmp.option("dropDownOptions.closeOnOutsideClick", true);
                            //tmp.option("showClearButton", true);

                        }
                        else if (details[j].type === 'String' || details[j].type === 'Email' || (details[j].type === 'Guid' && !columnElem.lookup)) {
                            if (details[j].maxLength > 100 || details[j].maxLength == null) {
                                item =
                                {
                                    dataField: details[j].name,
                                    label: {
                                        text: details[j].displayName
                                    },
                                    isRequired: details[j].isRequired,
                                    editorType: "dxTextArea",
                                    editorOptions: {
                                        showClearButton: true,
                                        maxLength: details[j].maxLength,
                                        height: 90,
                                        disabled: disabledItems || details[j].isDisabled,
                                    },
                                    helpText: details[j].info,
                                    visible: isVisible,
                                    validationRules: columnElem.dxErrorRules
                                };
                            } else {
                                item =
                                {
                                    dataField: details[j].name,
                                    label: {
                                        text: details[j].displayName
                                    },
                                    isRequired: details[j].isRequired,
                                    editorType: "dxTextBox",
                                    editorOptions: {
                                        maxLength: details[j].maxLength,
                                        showClearButton: true,
                                        disabled: disabledItems || details[j].isDisabled,
                                    },
                                    helpText: details[j].info,
                                    visible: isVisible,
                                    validationRules: columnElem.dxErrorRules
                                };
                            }

                        } else if (details[j].type === 'Pin' || details[j].type === 'Password') {

                            item = {
                                dataField: details[j].name,
                                label: {
                                    text: details[j].displayName
                                },
                                isRequired: details[j].isRequired,
                                editorType: "dxTextBox",
                                editorOptions: {
                                    mode: "password",
                                    maxLength: details[j].maxLength,
                                    showClearButton: true,
                                    disabled: disabledItems || details[j].isDisabled,
                                },
                                helpText: details[j].info,
                                visible: isVisible,
                                validationRules: columnElem.dxErrorRules
                            };

                        } else if (details[j].type === 'DateTime' ||
                            details[j].type === 'Date' ||
                            details[j].type === 'Time' ||
                            details[j].type === 'DateTimeSeconds') {
                            var displayFormat = "EEEE, dd MMM yyyy HH:mm";
                            if (details[j].type === 'Date') displayFormat = "EEEE, dd MMM yyyy";
                            if (details[j].type === 'Time') displayFormat = "HH:mm";
                            if (details[j].type === 'DateTimeSeconds') {
                                displayFormat = "EEEE, dd MMM yyyy HH:mm:ss";
                                details[j].type = "DateTime";
                            }

                            item = {
                                dataField: details[j].name,
                                label: {
                                    text: details[j].displayName
                                },
                                isRequired: details[j].isRequired,
                                editorType: "dxDateBox",
                                editorOptions: {
                                    type: details[j].type.toLowerCase(),
                                    displayFormat: displayFormat,
                                    dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
                                    calendarOptions: {
                                        firstDayOfWeek: 1
                                    },
                                    showClearButton: true,
                                    disabled: disabledItems || details[j].isDisabled,
                                },
                                helpText: details[j].info,
                                visible: isVisible,
                                validationRules: columnElem.dxErrorRules
                            };
                        } else if (details[j].type === 'Int32' ||
                            details[j].type === 'Int16' ||
                            details[j].type === 'Int64' ||
                            details[j].type === 'Byte' ||
                            details[j].type === 'Decimal' ||
                            details[j].type === 'Double') {
                            item = {
                                dataField: details[j].name,
                                label: {
                                    text: details[j].displayName
                                },
                                isRequired: details[j].isRequired,
                                editorType: "dxNumberBox",
                                editorOptions: { //todo add max values
                                    showSpinButtons: true,
                                    showClearButton: true,
                                    disabled: disabledItems || details[j].isDisabled,
                                    min: details[j].min,
                                    max: details[j].max
                                },
                                helpText: details[j].info,
                                visible: isVisible,
                                validationRules: columnElem.dxErrorRules
                            };
                        } else if (['Latitude', 'Longitude', 'SmallLatitude', 'SmallLongitude'].includes(
                            details[j].type)) {
                            var mask = '';
                            var regex = '';
                            var message = '';
                            if (details[j].type === 'Latitude') {
                                mask = 's00\u00B000\'00,000"';
                                regex = '^[-+][0-8]\\d[0-5]\\d[0-5]\\d\\d\\d\\d|^[-+]900000000';
                                message = sharedFunctions.getWebAppTranslation("fe7");
                            } else if (details[j].type === 'Longitude') {
                                mask = 's000\u00B000\'00,000"';
                                regex = '^[-+](([1][0-7])|([0]\\d))\\d[0-5]\\d[0-5]\\d\\d\\d\\d|^[-+]1800000000';
                                message = sharedFunctions.getWebAppTranslation("fe8");
                            } else if (details[j].type === 'SmallLatitude') {
                                mask = 's00\u00B000,000\'';
                                regex = '^[-+][0-8]\\d[0-5]\\d\\d\\d\\d|^[-+]9000000';
                                message = sharedFunctions.getWebAppTranslation("fe7");
                            } else if (details[j].type === 'SmallLongitude') {
                                mask = 's000\u00B000,000\'';
                                regex = '^[-+](([1][0-7])|([0]\\d))\\d[0-5]\\d\\d\\d\\d|^[-+]18000000';
                                message = sharedFunctions.getWebAppTranslation("fe8");
                            }
                            item = {
                                dataField: details[j].name,
                                label: {
                                    text: details[j].displayName
                                },
                                isRequired: details[j].isRequired,
                                editorType: "dxTextBox",
                                editorOptions: {
                                    showClearButton: true,
                                    disabled: disabledItems || details[j].isDisabled,
                                    mask: mask,
                                    name: details[j].name,
                                    maskRules: { "s": ['-', '+'] },
                                    //onKeyDown: function (e) {
                                    //    if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(e.event.key)) {
                                    //        console.log(e);
                                    //        var form = $("#" + classRef.ownContainer + " #details-form-container").dxForm("instance");

                                    //    }
                                    //}
                                },
                                helpText: details[j].info,
                                visible: isVisible,
                                validationRules: [
                                    {
                                        type: "pattern",
                                        pattern: regex,
                                        message: message
                                    }
                                ]
                            };
                        } else if (details[j].type === 'Boolean') {
                            item = {
                                dataField: details[j].name,
                                label: {
                                    text: details[j].displayName
                                },
                                //isRequired: details[j].isRequired, issue with required booleans
                                editorType: "dxCheckBox",
                                disabled: disabledItems || details[j].isDisabled,
                                visible: isVisible,
                                helpText: details[j].info,

                            };
                        } else if (details[j].type === 'File') {
                            item = {
                                dataField: details[j].name,
                                label: {
                                    text: details[j].displayName
                                },
                                editorOptions: {
                                    uploadMode: 'useForm',
                                },
                                helpText: details[j].info,
                                template: function(data, itemElement) {
                                    itemElement.append($("<div id='hello'>").dxFileUploader(data.editorOptions));
                                },
                                isRequired: details[j].isRequired,
                                disabled: disabledItems || details[j].isDisabled,
                                visible: isVisible

                            };
                        }
                        else if (details[j].type === 'Color') {
                            item =
                            {
                                dataField: details[j].name,
                                label: {
                                    text: details[j].displayName
                                },
                                isRequired: details[j].isRequired,
                                editorType: "dxColorBox",
                                editorOptions: {
                                    showClearButton: true,
                                    disabled: disabledItems || details[j].isDisabled,
                                },
                                helpText: details[j].info,
                                visible: isVisible,
                            };
                        }

                        //todo add other types if needed

                        if (workGrouped) {

                            if (classRef.controls.find(x => x.caption === details[j].group) === undefined) {
                                var groupItem = {
                                    itemType: "group",
                                    caption: details[j].group,
                                    colSpan: 2,
                                    colCount: 2,
                                    items: []
                                }
                                if (details[j].insertEmptyItemBefore) {
                                    groupItem.items.push({
                                        itemType: 'empty',
                                        visible: isVisible
                                    });
                                }

                                groupItem.items.push(item);

                                classRef.controls.push(groupItem);
                            } else {
                                if (details[j].insertEmptyItemBefore) {
                                    classRef.controls.find(x => x.caption === details[j].group).items.push({
                                        itemType: 'empty',
                                        visible: isVisible
                                    });
                                }
                                classRef.controls.find(x => x.caption === details[j].group).items.push(item);
                            }


                        } else {
                            if (details[j].insertEmptyItemBefore) {
                                classRef.controls.push({
                                    itemType: 'empty',
                                    visible: isVisible
                                });
                            }
                            classRef.controls.push(item);
                        }
                }


                for (var i = 0; i < classRef.controls.filter(c => c.visible == true).length; i++) {
                    if (classRef.controls.filter(c => c.visible == true)[i].itemType === "group") {
                        for (var k = 0; k < classRef.controls.filter(c => c.visible == true)[i].items.length; k++) {
                            if (k % 2 === 0 && classRef.controls.filter(c => c.visible == true)[i].items[k].itemType === "empty") {
                                classRef.controls.filter(c => c.visible == true)[i].items[k].itemType = "remove";
                                    break;
                                }
                            }
                        classRef.controls.filter(c => c.visible == true)[i].items = classRef.controls.filter(c => c.visible == true)[i].items.filter(el => el.itemType !== "remove");
                        } else {
                        if (i % 2 === 0 && classRef.controls.filter(c => c.visible == true)[i].itemType === "empty") {
                            classRef.controls.filter(c => c.visible == true)[i].itemType = "remove";
                                break;
                            }
                        }
                    }
                    classRef.controls = classRef.controls.filter(el => el.itemType !== "remove");

                    //console.log(dataObj);


                    //code below is for bulk editing
                    if (classRef.numberOfItems > 1) {

                        $("#" + classRef.ownContainer + " #details-list").dxList({
                            items: classRef.selectionList,
                            allowItemDeleting: false,
                            //itemDeleteMode: "toggle",
                            showSelectionControls: true,
                            selectionMode: "multiple",
                            onSelectionChanged: function(e) {
                                for (var i = 0; i < e.addedItems.length; i++) {
                                    var display = classRef.data.Item.find(x => x.displayName === e.addedItems[i]).name;
                                    $("#" + classRef.ownContainer + " #details-form-container").dxForm("instance")
                                        .itemOption(display, 'visible', true);
                                }
                                for (var i = 0; i < e.removedItems.length; i++) {
                                    var display = classRef.data.Item.find(x => x.displayName === e.removedItems[i])
                                        .name;
                                    $("#" + classRef.ownContainer + " #details-form-container").dxForm("instance")
                                        .itemOption(display, 'visible', false);
                                }
                            },
                            //onItemDeleted: updateSelectedItems,
                            onContentReady: function(e) {
                                const itemCount = e.component.option("items").length;
                                const rowCount = Math.ceil(itemCount / 4);
                                e.component.option("height", rowCount * 39 + 39);
                                //const dataSource = e.component.option("dataSource");
                            }
                        });

                        $("#" + classRef.ownContainer + " #details-list")
                            .prepend('<h3>' + sharedFunctions.getWebAppTranslation("fe9") + '</h3>');
                        $("#" + classRef.ownContainer + " #details-form")
                            .prepend('<h3>' + sharedFunctions.getWebAppTranslation("fe10") + '</h3>');

                    }

                    $("#" + classRef.ownContainer + " #details-form-container").dxForm({
                        colCount: 2,
                        formData: window.dataObj,
                        items: classRef.controls,
                        showValidationSummary: false,
                        validationGroup: "groupName",
                        showColonAfterLabel: false,
                        onFieldDataChanged: function(e) {

                            //var updatedField = e.dataField;
                            //var newValue = e.value;

                            // Event handling commands go here 
                            //var columnsLoc = columns;
                            // keep parentlookup up to date
                            var lookupQsElem = window.lookupQsDict.filter(x => x.module === window.currentModule &&
                                x.parentLookup === e.dataField);
                            if (lookupQsElem.length > 0) {
                                for (var i = 0; i < lookupQsElem.length; i++) {
                                    lookupQsElem[i].parentQs = "&" + lookupQsElem[i].parentLookup + "=" + e.value;
                                    this.updateData(lookupQsElem[i].lookup, null);
                                    var columnElem = columns.find(x => x.dataField === lookupQsElem[i].lookup);
                                    columnElem.lookup.dataSource.store.clearRawDataCache();
                                }
                                
                                this.repaint();

                            }

                            classRef.isChanged = true;

                            if (window.customFormFieldChange) {
                                window.customFormFieldChange(this, e);
                            }

                            //dispatch event to custom logic
                            window.dispatchEvent(new CustomEvent("dxFormFDChanged-" + window.currentModule,
                                { "detail": e }));

                        },
                        onInitialized: function(e) {
                            //dispatch event to custom logic
                            window.dispatchEvent(new CustomEvent("dxFormInitialized-" + window.currentModule,
                                { "detail": e }));
                        },
                        onEditorEnterKey: function(e) {
                            e.event.preventDefault();
                            var items = classRef.data.Item;
                            items.sort((a, b) => a.sequence < b.sequence ? -1 : (a.sequence > b.sequence ? 1 : 0));
                            var item = items.find(item => item.name === e.dataField);
                            if (item.enterAsTabKey == true) {
                                var index = items.findIndex(item => item.name === e.dataField);
                                this.getEditor(items[++index < items.length ? index : 0].name).focus();
                            }

                        },
                        onContentReady: function (e) {
                            if (window.detailsPageRendered && window.lastDetailsPageRender !== currentModule) {
                                window.lastDetailsPageRender = currentModule;
                                window.detailsPageRendered(window.currentModule);
                            }
                        }

                    }).dxForm("instance");

                    $("#summary").dxValidationSummary({ validationGroup: "groupName" }).dxValidationSummary("instance");


                    classRef.updateBreadCrumb();

                    sharedFunctions.hideLoadPanel();

                }
                .bind(this),
                function(jqXHR, textStatus, errorThrown) {

                    sharedFunctions.hideLoadPanel();

                    if (jqXHR.status === 401) {
                        sharedFunctions.logout();
                    } else {
                        sharedFunctions.showMessage(textStatus, sharedFunctions.getWebAppTranslation("fe13"));
                    }
                });
    }


    addEventHandlers() {
        var classRef = this;
        if (!classRef.readOnly) {
            $('#' + classRef.ownContainer + ' #btn-save').off("click").on("click", function () {
                classRef.urlAfterSave = classRef.generateListUrl();
                classRef.save(true);
            });
        }
        $('#' + classRef.ownContainer + ' #btn-close').off("click").on("click", function () { sharedFunctions.navigateToUrl(classRef.generateListUrl()) });
        $('#' + classRef.ownContainer + ' #btn-previous').off("click").on("click", function () { classRef.navigate(-1) });
        $('#' + classRef.ownContainer + ' #btn-next').off("click").on("click", function () { classRef.navigate(1) });
    }

    //event handlers
    alertNotImplemented(event) {
        alert('not yet implemented');
    }


    generateListUrl() {
        var hashUrl;
        var url;
        var currentQsObj = this.currUrlObj.qsObj;
        var mode = currentQsObj.mode;
        if (mode === 'onetoone') {
            //return to previous module or to default
            if (this.lastUrlObj.url != '') {
                var lastQsObj = this.lastUrlObj.qsObj;
                var lastQsDetails = new URLSearchParams(lastQsObj).toString();
                hashUrl = this.lastUrlObj.url + "?" + lastQsDetails;
                return decodeURIComponent(hashUrl);
            } else {
                hashUrl = window.appConfig.defaultUrl;
                return decodeURIComponent(hashUrl);
            }
        } else if (mode === "navToPrev") {
            if (this.lastUrlObj.url != '') {
                var lastQsObj = this.lastUrlObj.qsObj;
                var lastQsDetails = new URLSearchParams(lastQsObj).toString();
                url = window.location.origin + '/' + this.lastUrlObj.url + "?" + lastQsDetails;
                return decodeURIComponent(url);

            } else {
                url = window.location.origin + '/' + window.appConfig.defaultUrl;
                return decodeURIComponent(url);
            }
        }
        else {
            var currentModule = currentQsObj.module;
            hashUrl = this.listUrl + '?module=' + currentModule + '&' + this.breadcrumbComponent.generateBreadcrumbQueryString(currentModule, false);
            return hashUrl;
        }
    }


    save() {
        var classRef = this;

        if (!$(event.target).hasClass('appbarDisabled')) {
            var form = $("#" + this.ownContainer + " #details-form-container").dxForm("instance");
            var formValidation = form.validate();

            if (!formValidation.isValid) {
                return;
            } else {
                this.saveWithValidation();
            }
        }
    }

    saveWithValidation() {
        sharedFunctions.showLoadPanel();
        var classRef = this;
        var obj = $("#" + classRef.ownContainer + " #details-form-container").dxForm("instance").option('formData');

        if (classRef.numberOfItems > 1) {
            var ids = sharedFunctions.getCurrentUrlObj().qsObj['ids'];
            var selectedItems = $("#" + classRef.ownContainer + " #details-list").dxList("instance").option("selectedItems");
            var newObj = { 'ID': ids };
            for (var i = 0; i < selectedItems.length; i++) {
                var prop = classRef.data.Item.find(x => x.displayName === selectedItems[i]).name;

                newObj[prop] = obj[prop];
            }
            obj = newObj;
        }

        var url = '../api/details/save?module=' +
            window.currentModule +
            '&ignoreWarnings=false&mode=' + classRef.currUrlObj.qsObj.mode + '&' +
            this.breadcrumbComponent.generateBreadcrumbQueryString(window.currentModule, false);

        $.ajax({
            url: url,
            type: 'POST',
            contentType: 'application/json',
            dataType: "json",
            data: JSON.stringify(obj),
        }).done(function (result) {
            classRef.handleValidation(result);
        }

        ).fail(function (xhr, textStatus, errorThrown) {
            sharedFunctions.showMessage(xhr.responseText, sharedFunctions.getWebAppTranslation("fe13"));
            if (result.status === 401) {
                sharedFunctions.logout();
            }
        })
            .always(function () {
                sharedFunctions.hideLoadPanel();
            });
    }

    handleValidation(result) {
        var classRef = this;
        if (result.Error) {
            sharedFunctions.showMessage(result.Error, sharedFunctions.getWebAppTranslation("fe13"));
        }
        var serverValidation = result.Result;
        if (serverValidation.customErrObj && serverValidation.customErrObj.length != 0)
        {
            if (window.handleCustomValidation) {
                window.handleCustomValidation(this, serverValidation.customErrObj);
            }
        }
        else if (serverValidation.errorMessages.length != 0) {
            sharedFunctions.showMessage(sharedFunctions.getWebAppTranslation("fe27"), sharedFunctions.getWebAppTranslation("fe13"));
            var items = []; //$("#summary").dxValidationSummary("instance").option("items");
            var messagesLength = serverValidation.errorMessages.length;
            for (var i = 0; i < messagesLength; i++) {
                items.push({ text: serverValidation.errorMessages[i] });
            }
            $("#summary").dxValidationSummary("instance").option("items", items);
            return;
        }
        else if (serverValidation.warningMessages.length != 0) {
            var warningsHtml = sharedFunctions.getWarningsHtml(serverValidation.warningMessages);
            var messageResult = DevExpress.ui.dialog.confirm(warningsHtml, sharedFunctions.getWebAppTranslation("fe11"));

            messageResult.done(function (dialogResult) {
                if (dialogResult) {
                    classRef.saveIgnoreWarnings();
                }
                else {
                    return;
                }
            });
        } else {
            sharedFunctions.showToast(sharedFunctions.getWebAppTranslation("fe12"), "success", 1000);
            sharedFunctions.navigateToUrl(classRef.urlAfterSave);
        }
    }

    saveIgnoreWarnings() {
        sharedFunctions.showLoadPanel();
        var classRef = this;
        var obj = $("#" + classRef.ownContainer + " #details-form-container").dxForm("instance").option('formData');

        if (classRef.numberOfItems > 1) {
            var ids = sharedFunctions.getCurrentUrlObj().qsObj['id'];
            var selectedItems = $("#" + classRef.ownContainer + " #details-list").dxList("instance").option("selectedItems");
            var newObj = { 'ID': ids };
            for (var i = 0; i < selectedItems.length; i++) {
                var prop = classRef.data.Item.find(x => x.displayName === selectedItems[i]).name;

                newObj[prop] = obj[prop];
            }
            obj = newObj;
        }

        var url = '../api/details/save?module=' + window.currentModule + '&ignoreWarnings=true&mode=' + classRef.currUrlObj.qsObj.mode + '&' +
            this.breadcrumbComponent.generateBreadcrumbQueryString(window.currentModule, true);

        $.ajax({
            url: url,
            type: 'POST',
            contentType: 'application/json',
            dataType: "json",
            data: JSON.stringify(obj),
        }).done(function (result) {
                //todo handle unauthorized
            if (result.Error) {
                sharedFunctions.showMessage(result.Error, sharedFunctions.getWebAppTranslation("fe13"));
                return;
            } else {
                sharedFunctions.showToast(sharedFunctions.getWebAppTranslation("fe12"), "success", 1000);
                sharedFunctions.navigateToUrl(classRef.urlAfterSave);
            }

        }).fail(function (xhr, textStatus, errorThrown) {
            sharedFunctions.showMessage(xhr.responseText, sharedFunctions.getWebAppTranslation("fe13"));
            if (result.status === 401) {
                sharedFunctions.logout();
            }
        })
            .always(function () {
                sharedFunctions.hideLoadPanel();
            });

    }

    setCurrentModule(module) {
        if (window.currentModule !== module) {
            window.currentModule = module;
        }
    }

    toggleControl(property) {
        alert(property);
    }

    updateBreadCrumb() {
        this.breadcrumbComponent.render();
    }

    navigate(to) {
        var classRef = this;
        var direction = "next";
        if (to < 1) {
            direction = "previous";
        }
        if (this.action === "Create") {
            sharedFunctions.showMessage(sharedFunctions.getWebAppTranslation("fe15"), sharedFunctions.getWebAppTranslation("fe13"));
            return;
        }
        if (this.numberOfItems > 1) {
            sharedFunctions.showMessage(sharedFunctions.getWebAppTranslation("fe16"), sharedFunctions.getWebAppTranslation("fe13"));
            return;
        }
        var grid = sharedFunctions.getGrid();
        if (grid === null) {
            if (direction === "previous") {
                sharedFunctions.showMessage(sharedFunctions.getWebAppTranslation("fe17"), sharedFunctions.getWebAppTranslation("fe13"));
            } else {
                sharedFunctions.showMessage(sharedFunctions.getWebAppTranslation("fe25"), sharedFunctions.getWebAppTranslation("fe13"));
            }
            return;
        }
        var key = this.currUrlObj.qsObj.ids;
        var index = grid.getRowIndexByKey(key);
        if (index < 0) {
            sharedFunctions.showMessage(sharedFunctions.getWebAppTranslation("fe19"), sharedFunctions.getWebAppTranslation("fe13"));
            return;
        }
        if (to > 0) {
            if (index === grid.totalCount() - 1) {
                sharedFunctions.showMessage(sharedFunctions.getWebAppTranslation("fe20"), sharedFunctions.getWebAppTranslation("fe13"));
                return;
            }
        } else {
            if (index === 0) {
                sharedFunctions.showMessage(sharedFunctions.getWebAppTranslation("fe21"), sharedFunctions.getWebAppTranslation("fe13"));
                return;
            }
        }

        var newId = grid.getKeyByRowIndex(index + to);

        if (!newId) {
            sharedFunctions.showMessage(sharedFunctions.getWebAppTranslation("fe22"), sharedFunctions.getWebAppTranslation("fe13"));
            return;
        }

        var qsObj = this.currUrlObj.qsObj;
        qsObj.ids = newId;

        var qsDetails = new URLSearchParams(qsObj).toString();
        var newUrl = "#/details?" + qsDetails;

        //decode for the breadcrumb
        newUrl = decodeURIComponent(newUrl);

        this.urlAfterSave = newUrl;

        if (this.isChanged) {
            var result =
                DevExpress.ui.dialog.confirm(sharedFunctions.getWebAppTranslation("fe23"), "Confirm");
            result.done(function (dialogResult) {
                if (!dialogResult) {
                    return;
                } else {
                    classRef.save();
                }
            });
        } else {
            grid.selectRows([newId]);
            window.location.hash = newUrl;
        }
    }
}


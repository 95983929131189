import ilvoComponent from './ilvoComponent';
import domManager from '../utils/domManager';


export default class forgotPasswordIlvo1 extends ilvoComponent {
    constructor(parentContainer, ownContainer) {
        super(parentContainer, ownContainer);

        var reCaptchaSiteKey = window.appConfig.reCaptchav3SiteKey;

        this.htmlString = `
        <div class="loginIlvo1" id="${this.ownContainer}">
    <div class="fw-header">
        <div class="fw-width">
            <img src="/project/img/${sharedFunctions.getEnvironmentLogo()}" />
            <div class="user-header-info"></div>
        </div>
    </div>
            <section class="ftco-section">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-6">
                                <img id="login-project-logo" />

                            <div class="wrap">

                                <div class="login-wrap p-4 p-md-5">
                                    <div id="d1-auth-success" style="display:none">
<span id="d1-success-icon" class="material-icons">check_circle</span> ${sharedFunctions.getWebAppTranslation("fe29")}
                                    </div>
                                    <div id="d1-auth-user-input">
                                    <div class="d-flex d1-auth-title">
                                        <div class="w-100">
                                            <h3 class="mb-4">${sharedFunctions.getWebAppTranslation("fe30")}</h3>
                                        </div>
                                    </div>
                                    <div id="d1-forgotpw-validation"></div>
                                    <form id="d1-forgotpw-form" action="#" class="signin-form">
                                        <input type="hidden" id="d1-forgotpw-captcha" name="captcha" id="captcha-input" />
                                        <div class="form-group mt-3">
                                            <div class="dx-field">
                                                <div id="d1-forgotpw-email">
                                                </div>
                                            </div>
                                           
                                            
                                            <div class="dx-field">
                                                <div id="d1-forgotpw-btn">
                                                </div>
                                            </div>
                                            
                                    </form>
                                    <p style="margin-top:10px" class="text-center"><a href="#/login">${sharedFunctions.getWebAppTranslation("fe31")}</a></p>
</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
<script src="https://www.google.com/recaptcha/api.js?render=${reCaptchaSiteKey}"></script>
        `;

    }

    render(currUrlObj, lastUrlObj) {
        this.currUrlObj = currUrlObj;
        this.lastUrlObj = lastUrlObj;

        this.clearRenderedData();

        this.renderCoreContent();

        //loadAndRender is not split up yet
        this.loadAndRenderData();

        this.addEventHandlers();
    }

    clearRenderedData() {
        if (domManager.existsId(this.ownContainer)) {
            domManager.removeId(this.ownContainer);
        }
    }

    loadAndRenderData() {
        if (window.appConfig.user && window.appConfig.user.username) {
            window.location.hash = window.appConfig.defaultUrl;
        }

        window.currentPage = 'forgotpw';

         sharedFunctions.addUserInHeader("#" + this.ownContainer + " .user-header-info");

        $('#d1-forgotpw-validation').dxValidationSummary({});

        $("#d1-forgotpw-email").dxTextBox({
            placeholder: sharedFunctions.getWebAppTranslation("fe46"),
            height: 48,
            name: "email",
            mode: 'email'
        }).dxValidator({
            validationRules: [{
                type: 'required',
                message: sharedFunctions.getWebAppTranslation("fe47"),
            }, {
                type: 'email',
                message: sharedFunctions.getWebAppTranslation("fe48")
            }],
        });

        $("#d1-forgotpw-btn").dxButton({
            stylingMode: "contained",
            text: sharedFunctions.getWebAppTranslation("fe44"),
            type: "default",
            width: "100%",
            height: 48,
            useSubmitBehavior: true,

        });

        $('#d1-forgotpw-form').on('submit', (e) => {
            sharedFunctions.showLoadPanel();
            grecaptcha.ready(function () {
                grecaptcha.execute(window.appConfig.reCaptchav3SiteKey, { action: 'fortgot' }).then(function (token) {
                    $('#d1-forgotpw-captcha').val(token);
                    var formData = $('#d1-forgotpw-form').serializeArray();
                    formData.push({ name: "language", value: sharedFunctions.getLanguage() });
                    $.ajax({
                        method: "POST",
                        data: formData,
                        url: window.fwApi + "/forgotpassword"
                    })
                        .done(function (result) {
                            if (result.Error) {
                                sharedFunctions.showMessage(result.Error, "Error");
                            } else {
                                $("#d1-auth-user-input").hide();
                                $("#d1-auth-success").show();
                            }

                        })
                        .fail(function (result) {
                            var errorResult = JSON.parse(result.responseText);
                            var errorHtml = "";
                            if (errorResult.Error) {
                                errorHtml += "<p>" + errorResult.Error + "</p>";
                            }
                            else if (errorResult.Errors) {
                                for (var i = 0; i < errorResult.Errors.length; i++) {
                                    errorHtml += "<p>" + errorResult.Errors[i] + "</p>";
                                }
                            }
                            $("#d1-forgotpw-validation").html(errorHtml);
                        })
                        .always(function (result) {
                            sharedFunctions.hideLoadPanel();
                        });
                });
            });
            e.preventDefault();
        });

    }

    addEventHandlers() {
        var classRef = this;
        $('#' + classRef.ownContainer + ' .d1-lang-item').off('click');
        $('#' + classRef.ownContainer + ' .d1-lang-item').on('click', function () { sharedFunctions.changeLanguage($(this).attr('lang')) });
    }
}


import ilvoComponent from './ilvoComponent'

export default class errorIlvo1 extends ilvoComponent{
    constructor(parentContainer, ownContainer) {
        super(parentContainer, ownContainer);

        this.htmlString = ` 
         <div class="errorIlvo1" id="${this.ownContainer}">
	        <h2 style="text-align:center;">Url redirect error message here.</h2>
        </div>
        `;    
        
    }
}


import ilvoComponent from './ilvoComponent'
import domManager from '../utils/domManager'

export default class navDx1 extends ilvoComponent {
    constructor(parentContainer, ownContainer, mainContentTag) {
        super(parentContainer, ownContainer);
        this.mainContentTag = mainContentTag;

        this.sideMenu = {};

        this.htmlString = ` 
         <div class="navDx1" id="${this.ownContainer}">
	        <div id="ilvo-side-menu-container">
	        <div id="ilvo-side-menu-toggle"></div>
		        <div id="${this.mainContentTag}" />
	        </div>
        </div>
        `;     //test   
    }

    renderData(currUrlObj, lastUrlObj) {
        var localconfig = window.appConfig;

        domManager.elemById('ilvo-side-menu-toggle').dxButton({
            icon: "menu"
        });

        this.sideMenu = domManager.elemById('ilvo-side-menu-container').dxDrawer({
            opened: false,
            position: 'left',
            openedStateMode: 'shrink',
            height: '100vh',
            shading: true,
            closeOnOutsideClick: true,
            template: function () {


                if (localconfig.useCustomHtmlSideMenu) {
                    var customSideMenu = window.customSideMenu;
                    if (customSideMenu) {
                        return customSideMenu;
                    }
                }


                var html = "<div id='ilvo-side-menu'>";
                html += "<div id='side-menu-menuGroups'>";
                //html += "<b>";



                html += "</div>";
                html += "</div>";

                return html;
            }
        }).dxDrawer("instance");

        //var allMenuGroups = [...new Set(localconfig.sideMenu.filter(x => x.name != null).map(item => item.name))];
        //var allItems = [...new Set(localconfig.sideMenu.filter(x => x.name != null).map(item => item.linkItems))];
        //allItems = [].concat(...allItems);

        //for (var i = 0; i < allMenuGroups.length; i++) {
        //    html += "<h3>" + allMenuGroups[i] + "</h3>";
        //    var modules = localconfig.sideMenu.filter(x => x.name === allMenuGroups[i]).map(item => item.linkItems)[0];
        //    for (var j = 0; j < modules.length; j++) {
        //        html += "<p webModule='" + modules[j].webModule + "' customUrl='" + modules[j].customUrl + "'>" + modules[j].label + "</p>";
        //    }

        //}

        $("#side-menu-menuGroups").dxList({
            dataSource: new DevExpress.data.DataSource({
                store: localconfig.sideMenu,
                map: function (item) {
                    return {
                        key: item.name,
                        items: item.linkItems,
                        isExpanded: item.isExpanded
                    }
                }
            }),
            //menuItems: allMenuGroups,
            //height: "100%",
            grouped: true,
            collapsibleGroups: true,
            groupTemplate: function (data) {
                return $("<div>" + data.key + "</div>");

            },
            itemTemplate: function (data, _, element) {
                var icon = "";
                if (data.isExternal) {
                    icon = ' <span class="material-icons" style="font-size: 18px;vertical-align: middle;line-height: 19px;">open_in_new</span >';
                }
                element.append(
                    $("<p>").html(data.label + icon)
                        .attr("webModule", data.webModule)
                        .attr("customUrl", data.customUrl)
                        .attr("isExternal", data.isExternal)
                );

            },
            onContentReady: function (e) {
                //setTimeout(function () {
                    var items = e.component.option("items");
                for (var i = 0; i < items.length; i++)
                    if (!items[i].isExpanded) {
                        e.component.collapseGroup(i);
                    }
                //}, 50);
                if (window.sideMenuReady) {
                    window.sideMenuReady();
                }
            }  
            //itemTemplate: function (data, index) {
            //    //var result = $("<div>test</div>").addclass("product");
            //    var result = $("<p>test</p>");

            //    $("<img>").attr("src", data.imagesrc).appendto(result);
            //    $("<div>").text(data.name).appendto(result);
            //    $("<div>").addclass("price")
            //        .html(formatcurrency(data.price)).appendto(result);

            //    return result;

            //}
        }).dxList("instance");


        $("#ilvo-side-menu").dxScrollView({
            scrollByContent: true, // enables the swipe gesture on all platforms
            scrollByThumb: true // makes the scrollbar active on all platforms
        });

        window.navMenu = this;
    }

    addEventHandlers(currUrlObj, lastUrlObj) {
        var classRef = this;
        //$('#ilvo-side-menu-toggle').off('click').on('click', classRef.toggleSideMenu.bind(classRef));
        //$('#ilvo-side-menu p').off('click').on('click', classRef.goToList.bind(classRef));
        //domManager.addEventHandlerById('ilvo-side-menu-toggle', "click", this.toggleSideMenu.bind(this));
        //domManager.addEventHandlerById('ilvo-side-menu p', "click", this.goToList.bind(this));

        $('body').on('click', '#ilvo-side-menu-toggle', classRef.toggleSideMenu.bind(classRef));
        $('body').on('click', '#ilvo-side-menu p', classRef.goToList.bind(classRef));
    }

    refresh() {
        this.renderData();
    }

    //event handlers
    toggleSideMenu(event) {
        //alert("test");
        this.sideMenu.toggle();
    }

    goToList(event) {
        if (event.target.attributes.customUrl && event.target.attributes.customUrl.value.length > 0) {
            if (event.target.attributes.customUrl.value.includes('#')) {
                window.location.hash = event.target.attributes.customUrl.value;
            }
            else if (event.target.attributes.isExternal && event.target.attributes.isExternal.value) {
                window.open(event.target.attributes.customUrl.value, '_blank');
            }
            else {
                window.open(event.target.attributes.customUrl.value);
            }
        }
        else if (event.target.attributes.webModule && event.target.attributes.webModule.value.length > 0) {
            window.location.hash = '#/list' + '?module=' + event.target.attributes.webModule.value;
        }
    }
}


import ilvoComponent from './ilvoComponent';
import domManager from '../utils/domManager';


export default class loginIlvo1 extends ilvoComponent{
    constructor(parentContainer, ownContainer) {
        super(parentContainer, ownContainer);

        this.htmlString = `
        <div class="loginIlvo1" id="${this.ownContainer}">
    <div class="fw-header">
        <div class="fw-width">
            <img src="/project/img/${sharedFunctions.getEnvironmentLogo()}" />
            <div class="user-header-info"></div>
        </div>
    </div>
            <section class="ftco-section">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-6">
                            <img id="login-project-logo" />
                            <div class="wrap">
                                <div class="login-wrap p-4 p-md-5">
                                    <div class="d-flex">
                                        <div class="w-100">
                                            <h3 class="mb-4">${sharedFunctions.getWebAppTranslation("fe32")}</h3>
                                        </div>
                                    </div>
                                    <p id="d1-login-error" class="text-center"></p>
                                    <form id="d1-login-form" action="#" class="signin-form">
                                        <div class="form-group mt-3">
                                            <div class="dx-field">
                                                <div id="d1-login-user">
                                                </div>
                                            </div>
                                            <div class="dx-field">
                                                <div id="d1-login-pw">
                                                </div>
                                            </div>
                                            <div class="dx-field">
                                                <div id="d1-login-btn">
                                                </div>
                                            </div>
                                            <div class="form-group d-md-flex">
                                                <div class="w-50 text-left">
                                                    <div id="d1-login-remember">
                                                    </div>
                                                </div>
                                            <div class="w-50 text-md-right">
                                                <a href="#/forgot-password">${sharedFunctions.getWebAppTranslation("fe30")}</a>
                                            </div>
                                        </div>
                                    </form>
                                    <p class="text-center">${sharedFunctions.getWebAppTranslation("fe53")} <a id="d1-register-link" data-toggle="tab" href="#/register">${sharedFunctions.getWebAppTranslation("fe33")}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        `;    
        
    }

    render(currUrlObj, lastUrlObj) {
        this.currUrlObj = currUrlObj;
        this.lastUrlObj = lastUrlObj;

        this.clearRenderedData();

        this.renderCoreContent();

        //loadAndRender is not split up yet
        this.loadAndRenderData();

        this.addEventHandlers();
    }

    clearRenderedData() {
        if (domManager.existsId(this.ownContainer)) {
            domManager.removeId(this.ownContainer);
        }
    }

    loadAndRenderData() {
        if (window.appConfig.user && window.appConfig.user.username) {
            window.location.hash = window.appConfig.defaultUrl;
        }

        window.currentPage = 'login';

         sharedFunctions.addUserInHeader("#" + this.ownContainer + " .user-header-info");

        $("#d1-login-user").dxTextBox({
            placeholder: sharedFunctions.getWebAppTranslation("fe49"),
            height: 48
        });
        $("#d1-login-pw").dxTextBox({
            placeholder: sharedFunctions.getWebAppTranslation("fe40"),
            height: 48,
            mode: "password",
        });
        $("#d1-login-remember").dxCheckBox({
            text: sharedFunctions.getWebAppTranslation("fe54")
        });
        $('#d1-login-form').on('submit', (e) => {
            //DevExpress.ui.notify({
            //    message: 'You have submitted the form',
            //    position: {
            //        my: 'center top',
            //        at: 'center top',
            //    },
            //}, 'success', 3000);

            $("#d1-login-error").text("");
            sharedFunctions.showLoadPanel();
                $.ajax({
                            method: "POST",
                            data: {
                                username: $("#d1-login-user").dxTextBox('instance').option('value'),
                                password: $("#d1-login-pw").dxTextBox('instance').option('value'),
                                remember: $("#d1-login-remember").dxCheckBox('instance').option('value'),
                                language: sharedFunctions.getLanguage()
                            },
                            url: window.fwApi + "/login"
                        })
                            .done(function (result) {
                                if (result.Error) {
                                    sharedFunctions.showMessage(result.Error, sharedFunctions.getWebAppTranslation("fe13"));
                                } else {
                                    $.ajax({
                                        method: "GET",
                                        url: window.fwApi + "/appconfig"
                                    })
                                        .done(function (result) {
                                            if (result.Error) {
                                                sharedFunctions.showMessage(result.Error, sharedFunctions.getWebAppTranslation("fe13"));
                                            } else {
                                                window.appConfig = result.Result;
                                                if (result.Result.user.language) {
                                                    DevExpress.localization.locale(result.Result.user.language);
                                                } else {
                                                    DevExpress.localization.locale("en");
                                                }
                                            }
                                            $('.grecaptcha-badge').hide();

                                            var newLocation = window.appConfig.defaultUrl;
                                            if (window.redirectAfterLoginObj) {
                                                newLocation = window.redirectAfterLoginObj.url + "?" + window.redirectAfterLoginObj.qs;
                                            }
                                            if (window.appConfig.defaultUrl.includes('#')) {
                                                window.location.hash = newLocation;
                                                window.navMenu.refresh();
                                            }
                                            else {
                                                window.location = newLocation;
                                            }
                                            window.redirectAfterLoginObj = undefined;

                                        })
                                        .fail(function (result) {
                                            sharedFunctions.showMessage(result.status, sharedFunctions.getWebAppTranslation("fe13"));
                                            // handle request failures
                                        });
                                    ;
                                }

                            })
                            .fail(function (result) {
                                $("#d1-login-error").text(result.responseText);
                                $("#d1-login-pw").dxTextBox("option", "value", "");
                                // handle request failures
                            })
                            .always(function (result) {
                                sharedFunctions.hideLoadPanel();
                            });;

            e.preventDefault();
        });


        $("#d1-login-btn").dxButton({
            stylingMode: "contained",
            text: sharedFunctions.getWebAppTranslation("fe32"),
            type: "default",
            width: "100%",
            height: 48,
            useSubmitBehavior: true,
            //onClick: function () {
            
                
            //}
        });

        $("#d1-register-link").click(function() {
            window.location.hash = "#/register";
        });

    }

    addEventHandlers() {
        var classRef = this;
        $('#' + classRef.ownContainer + ' .d1-lang-item').off('click');
        $('#' + classRef.ownContainer + ' .d1-lang-item').on('click', function () { sharedFunctions.changeLanguage($(this).attr('lang')) });
    }
}


import ilvoComponent from './ilvoComponent';
import domManager from '../utils/domManager';


export default class adminValidateAccountIlvo1 extends ilvoComponent {
    constructor(parentContainer, ownContainer) {
        super(parentContainer, ownContainer);

        this.htmlString = `
        <div class="loginIlvo1" id="${this.ownContainer}">
    <div class="fw-header">
        <div class="fw-width">
            <img src="/project/img/${sharedFunctions.getEnvironmentLogo()}" />
            <div class="user-header-info"></div>
        </div>
    </div>
            <section class="ftco-section">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-6">
                                <img id="login-project-logo" />

                            <div class="wrap">
                                <div class="login-wrap p-4 p-md-5">

                                    <div id="d1-auth-success" style="display:none">
<span id="d1-success-icon" class="material-icons">check_circle</span> ${sharedFunctions.getWebAppTranslation("fe28")}
                                    </div>
                                    <div id="d1-auth-fail" style="display:none">
<span id="d1-fail-icon" class="material-icons">cancel</span>
<div id="d1-validate-validation"></div>
                                    
</div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        `;

    }

    render(currUrlObj, lastUrlObj) {
        this.currUrlObj = currUrlObj;
        this.lastUrlObj = lastUrlObj;

        this.clearRenderedData();

        this.renderCoreContent();

        //loadAndRender is not split up yet
        this.loadAndRenderData();

        this.addEventHandlers();
    }

    clearRenderedData() {
        if (domManager.existsId(this.ownContainer)) {
            domManager.removeId(this.ownContainer);
        }
    }

    loadAndRenderData() {
        window.currentPage = 'adminvalidate';

        sharedFunctions.addUserInHeader("#" + this.ownContainer + " .user-header-info");

        $('#d1-validate-validation').dxValidationSummary({});

        sharedFunctions.showLoadPanel();

        $.ajax({
            method: "POST",
            data: {
                user: sharedFunctions.getCurrentUrlObj().qsObj.user,
                token: sharedFunctions.getCurrentUrlObj().qsObj.token,
                language: sharedFunctions.getLanguage()
            },
            url: window.fwApi + "/adminvalidateaccount"
        })
            .done(function (result) {
                if (result.Error) {
                    sharedFunctions.showMessage(result.Error, "Error");
                } else {
                    $("#d1-auth-success").show();
                }

            })
            .fail(function (result) {
                var errorResult = JSON.parse(result.responseText);
                var errorHtml = "";
                if (errorResult.Error) {
                    errorHtml += "<p>" + errorResult.Error + "</p>";
                }
                else if (errorResult.Errors) {
                    for (var i = 0; i < errorResult.Errors.length; i++) {
                        errorHtml += "<p>" + errorResult.Errors[i] + "</p>";
                    }
                }
                $("#d1-auth-fail").show();
                $("#d1-validate-validation").html(errorHtml);
            })
            .always(function (result) {
                sharedFunctions.hideLoadPanel();
            });

    }
}

